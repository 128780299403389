import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, notification, InputNumber, Table, Form, Card, Upload } from 'antd';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import Sidebar from '../../Sidebar';
import '../../PropertyData.css';

const UpdateBuildingPetal = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ updateHouseList, setUpdateHouseList ] = useState( [] );
    const [ selectedHouse, setSelectedHouse ] = useState( null );
    const [ blokKav, setBlokKav ] = useState( '' );
    const [ luasbangunan, setLuasBangunan ] = useState( '' );
    const [ checkedList, setCheckedList ] = useState( [] );
    const [ confirmLoading, setConfirmLoading ] = useState( false );
    const [ modalVisible, setModalVisible ] = useState( false );
    const [ kamartidur, setKamarTidur ] = useState( 0 );
    const [ kamarmandi, setKamarMandi ] = useState( 0 );
    const [ checkAll, setCheckAll ] = useState( false );
    const [ indeterminate, setIndeterminate ] = useState( false );
    const [ denahUrl, setDenahUrl ] = useState( '' );
    const CheckboxGroup = Checkbox.Group;

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const fetchData = () =>
    {
        axios.get( `${ API_URL }` )
            .then( result =>
            {
                const data = result.data;
                const filteredGambarKavling = data.filter( item => item.lokasi === 'Tanjung, Kedamean' );
                setUpdateHouseList( filteredGambarKavling );
            } )
            .catch( err => console.log( err ) );
    };

    const showModal = ( house ) =>
    {
        setSelectedHouse( house );
        setBlokKav( house.blokKav );
        setLuasBangunan( house.luasbangunan );
        setDenahUrl( house.denahUrl );
        setCheckedList( [
            house.ruangmakan && 'ruangmakan',
            house.kamarmandi && 'kamarmandi',
            house.kamartidur && 'kamartidur',
            house.carport && 'carport',
            house.dapur && 'dapur',
            house.ruangtamu && 'ruangtamu',
            house.taman && 'taman',
            house.ruangterbuka && 'ruangterbuka',
            house.kanopi && 'kanopi',
            house.pagar && 'pagar'
        ].filter( Boolean ) );
        setKamarTidur( house.kamartidur );
        setKamarMandi( house.kamarmandi );
        setModalVisible( true );
    };

    const handleOk = async () =>
    {
        setConfirmLoading( true );
        try
        {
            const updatedHouse = {
                blokKav,
                luasbangunan,
                checkedList,
                kamartidur,
                kamarmandi,
                ruangmakan: checkedList.includes( 'ruangmakan' ) ? 1 : '',
                carport: checkedList.includes( 'carport' ) ? 1 : '',
                dapur: checkedList.includes( 'dapur' ) ? 1 : '',
                ruangtamu: checkedList.includes( 'ruangtamu' ) ? 1 : '',
                taman: checkedList.includes( 'taman' ) ? 1 : '',
                ruangterbuka: checkedList.includes( 'ruangterbuka' ) ? 1 : '',
                kanopi: checkedList.includes( 'kanopi' ) ? 1 : '',
                pagar: checkedList.includes( 'pagar' ) ? 1 : '',
            };

            await axios.put( `${ API_URL }/updateProperty/${ selectedHouse._id }`, updatedHouse );
            notification.success( { message: 'Success', description: 'Property updated successfully' } );
            setUpdateHouseList( updateHouseList.map( h => h._id === selectedHouse._id ? { ...h, ...updatedHouse } : h ) );
            setModalVisible( false );
            fetchData();
        } catch ( error )
        {
            notification.error( { message: 'Error', description: 'Failed to update property' } );
        } finally
        {
            setConfirmLoading( false );
        }
    };

    const handleCancel = () =>
    {
        setModalVisible( false );
    };

    const onChange = ( list ) =>
    {
        setCheckedList( list );
    };

    const onCheckAllChange = ( e ) =>
    {
        const isChecked = e.target.checked;
        if ( isChecked )
        {
            setCheckedList( [
                'ruangmakan',
                'carport',
                'dapur',
                'ruangtamu',
                'taman',
                'ruangterbuka',
                'kanopi',
                'pagar',
            ] );
        } else
        {
            setCheckedList( [] );
        }
    };


    const handleUploadDenah = async ( file ) =>
    {
        const formData = new FormData();
        formData.append( 'denah', file );

        try
        {
            const response = await axios.post( `${ API_URL }/uploadDenah/${ selectedHouse._id }`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } );
            notification.success( { message: 'Success', description: 'Layout image uploaded successfully' } );
            setUpdateHouseList( updateHouseList.map( h => h._id === selectedHouse._id ? { ...h, denahUrl: response.data.denahUrl } : h ) );
            fetchData();
        } catch ( error )
        {
            notification.error( { message: 'Error', description: 'Failed to upload layout image' } );
        }
    };

    const handleRemoveDenah = async () =>
    {
        try
        {
            await axios.put( `${ API_URL }/removeDenah/${ selectedHouse._id }` );
            notification.success( { message: 'Success', description: 'Layout image removed successfully' } );
            setUpdateHouseList( updateHouseList.map( h => h._id === selectedHouse._id ? { ...h, denahUrl: '' } : h ) );
            fetchData();
        } catch ( error )
        {
            notification.error( { message: 'Error', description: 'Failed to remove layout image' } );
        }
    };

    const renderTableData = ( value, unit ) =>
    {
        return value ? `${ value } ${ unit }` : '';
    };

    const columns = [
        {
            title: 'Tindakan',
            dataIndex: 'actions',
            render: ( text, record ) => (
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    <Button onClick={ () => showModal( record ) } size='small' type='primary'>Edit</Button>
                </div>
            )
        },
        {
            title: 'Blok',
            dataIndex: 'blokKav',
            align: 'center',
            onFilter: ( value, record ) => record.blokKav.startsWith( value ),
        },
        {
            title: 'Luas Bangunan',
            dataIndex: 'luasbangunan',
            render: ( value ) => renderTableData( value, 'M²' )
        },
        {
            title: 'Kamar Tidur',
            dataIndex: 'kamartidur',
            align: 'center',
        },
        {
            title: 'Kamar Mandi',
            dataIndex: 'kamarmandi',
            align: 'center',
        },
        {
            title: 'Ruang Tamu',
            dataIndex: 'ruangtamu',
            render: ( value ) =>
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </div>,
        },
        {
            title: 'Denah',
            dataIndex: 'denahUrl',
            render: ( value ) =>
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </div>,
        },
        {
            title: 'Dapur',
            dataIndex: 'dapur',
            render: ( value ) =>
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </div>,
        },
        {
            title: 'Carport',
            dataIndex: 'carport',
            render: ( value ) =>
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </div>,
        },
        {
            title: 'Taman',
            dataIndex: 'taman',
            render: ( value ) =>
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </div>,
            align: 'center'
        },
        {
            title: 'Ruang Terbuka',
            dataIndex: 'ruangterbuka',
            render: ( value ) =>
                <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </div>,
        },
    ];
    const onChangeTable = ( pagination, filters, sorter, extra ) =>
    {
        console.log( 'params', pagination, filters, sorter, extra );
    };
    useEffect( () =>
    {
        const isCheckedAll = checkedList.length === 8;
        setCheckAll( isCheckedAll );
        setIndeterminate( checkedList.length > 0 && checkedList.length < 8 );
    }, [ checkedList ] );

    return (
        <div style={ { display: 'flex' } }>
            <section>
                <Sidebar />
            </section>
            <section className='sub-main-layout'>
                <Card size='small' className='card-table'>
                    <p className='title-list'>UPDATE BANGUNAN TANJUNG</p>
                    <Table
                        columns={ columns }
                        dataSource={ updateHouseList }
                        rowKey='_id'
                        onChange={ onChangeTable }
                        scroll={ { y: 340 } }
                        pagination={ { pageSize: 10 } }
                        size='small'
                    />
                </Card>
            </section>

            <Modal
                title="Tambah / Hapus Ruangan"
                visible={ modalVisible }
                onOk={ handleOk }
                confirmLoading={ confirmLoading }
                onCancel={ handleCancel }
            >
                <Form>
                    <Checkbox
                        indeterminate={ indeterminate }
                        onChange={ onCheckAllChange }
                        checked={ checkAll }
                    >
                        Pilih Semua / Hapus Semua
                    </Checkbox>
                    <hr className='hr' />
                    <CheckboxGroup value={ checkedList } onChange={ onChange }>
                        <Checkbox value="ruangmakan">Ruang Makan</Checkbox>
                        <Checkbox value="carport">Carport</Checkbox>
                        <Checkbox value="dapur">Dapur</Checkbox>
                        <Checkbox value="ruangtamu">Ruang Tamu</Checkbox>
                        <Checkbox value="taman">Taman</Checkbox>
                        <Checkbox value="ruangterbuka">Ruang Terbuka</Checkbox>
                        <Checkbox value="kanopi">Kanopi</Checkbox>
                        <Checkbox value="pagar">Pagar</Checkbox>
                    </CheckboxGroup>
                    <hr className='hr' />
                    <div className='div-input'>
                        <label htmlFor='luasbangunan'>Luas Bangunan</label>
                        <InputNumber
                            value={ luasbangunan }
                            min={ 0 }
                            placeholder='Luas'
                            onChange={ ( value ) => setLuasBangunan( value ) }
                        />
                    </div>
                    <div className='div-input'>
                        <label htmlFor='kamarTidur'>Kamar Tidur</label>
                        <InputNumber
                            value={ kamartidur }
                            placeholder='Jumlah'
                            onChange={ ( value ) => setKamarTidur( value ) }
                        />
                    </div>
                    <div className='div-input'>
                        <label htmlFor='kamarMandi'>Kamar Mandi</label>
                        <InputNumber
                            value={ kamarmandi }
                            min={ 0 }
                            placeholder='Jumlah'
                            onChange={ ( value ) => setKamarMandi( value ) }
                        />
                    </div>
                    <div className='upload-denah'>
                        <Upload
                            beforeUpload={ ( file ) =>
                            {
                                handleUploadDenah( file );
                                return false;
                            } }
                            showUploadList={ false }
                        >
                            <Button icon={ <UploadOutlined /> }>Upload Denah</Button>
                        </Upload>
                        <Button
                            disabled={ !denahUrl }
                            onClick={ handleRemoveDenah }
                            type={ !denahUrl ? 'dashed' : 'danger' }
                            icon={ <DeleteOutlined /> }
                        >
                        </Button>
                    </div>
                </Form>
            </Modal>
        </div>
    );
};

export default UpdateBuildingPetal;
