import React, { useEffect, useState } from 'react';
import { Button, Modal, notification, Input, Table, Card, Upload, Form, Flex } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import Sidebar from '../../Sidebar';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import '../../PropertyData.css';

const { TextArea } = Input;

const GaleriPetal = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ updateHouseList, setUpdateHouseList ] = useState( [] );
    const [ blokKav, setBlokKav ] = useState( '' );
    const [ confirmLoading, setConfirmLoading ] = useState( false );
    const [ selectedHouse, setSelectedHouse ] = useState( null );
    const [ modalVisible, setModalVisible ] = useState( false );
    const [ imageFiles, setImageFiles ] = useState( {} );

    useEffect( () =>
    {
        fetchData();
    }, [] );
    const fetchData = () =>
    {
        axios.get( `${ API_URL }` )
            .then( result =>
            {
                const data = result.data;
                const filteredGambarLokasi = data.filter( item => item.lokasi === 'Petal, Menganti' );
                setUpdateHouseList( filteredGambarLokasi );
            } )
            .catch( err => console.log( err ) );
    };
    const showModal = ( house ) =>
    {
        setSelectedHouse( house );
        setBlokKav( house.blokKav );
        setImageFiles( {
            image1: house.image1Url,
            image2: house.image2Url,
            image3: house.image3Url,
            image4: house.image4Url,
            image5: house.image5Url,
            image6: house.image6Url,
        } );
        setModalVisible( true );
    };
    const handleOk = async () =>
    {
        setConfirmLoading( true );

        const formData = new FormData();
        formData.append( 'blokKav', blokKav );
        if ( imageFiles.image1 ) formData.append( 'image1', imageFiles.image1 );
        if ( imageFiles.image2 ) formData.append( 'image2', imageFiles.image2 );
        if ( imageFiles.image3 ) formData.append( 'image3', imageFiles.image3 );
        if ( imageFiles.image4 ) formData.append( 'image4', imageFiles.image4 );
        if ( imageFiles.image5 ) formData.append( 'image5', imageFiles.image5 );
        if ( imageFiles.image6 ) formData.append( 'image6', imageFiles.image6 );

        try
        {
            await axios.put( `${ API_URL }/updateProperty/${ selectedHouse._id }`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            } );
            notification.success( { message: 'Success', description: 'Property updated successfully' } );
            setModalVisible( false );
            fetchData();
        } catch ( error )
        {
            notification.error( { message: 'Error', description: 'Failed to update property' } );
        } finally
        {
            setConfirmLoading( false );
        }
    };
    const handleCancel = () =>
    {
        setModalVisible( false );
    };
    const handleImageUpload = ( file, key ) =>
    {
        setImageFiles( { ...imageFiles, [ key ]: file } );
        return false;
    };
    const handleImageRemove = async ( key ) =>
    {
        if ( selectedHouse && selectedHouse._id )
        {
            try
            {
                await axios.put( `${ API_URL }/deleteImage/${ selectedHouse._id }/${ key }` );
                notification.success( { message: 'Success', description: 'Image removed successfully' } );
                setImageFiles( { ...imageFiles, [ key ]: null } );
                fetchData(); // Memperbarui data setelah menghapus gambar
            } catch ( error )
            {
                notification.error( { message: 'Error', description: 'Failed to remove image' } );
            }
        }
    };
    const columns = [
        {
            title: 'Tindakan',
            dataIndex: 'actions',
            align: 'center',
            render: ( text, record ) => (
                <Flex justify='center' align='center'>
                    <Button onClick={ () => showModal( record ) } size='small' type='primary'>Edit</Button>
                </Flex>
            )
        },
        {
            title: 'Blok',
            align: 'center',
            dataIndex: 'blokKav',
            onFilter: ( value, record ) => record.blokKav.startsWith( value ),
        },
        {
            title: 'Gambar 1',
            align: 'center',
            dataIndex: 'image1Url',
            render: ( value ) => <div className='icon-style-list'>{ value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }</div>,
        },
        {
            title: 'Gambar 2',
            align: 'center',
            dataIndex: 'image2Url',
            render: ( value ) => <div className='icon-style-list'>{ value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }</div>,
        },
        {
            title: 'Gambar 3',
            align: 'center',
            dataIndex: 'image3Url',
            render: ( value ) => <div className='icon-style-list'>{ value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }</div>,
        },
        {
            title: 'Gambar 4',
            align: 'center',
            dataIndex: 'image4Url',
            render: ( value ) => <div className='icon-style-list'>{ value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }</div>,
        },
        {
            title: 'Gambar 5',
            align: 'center',
            dataIndex: 'image5Url',
            render: ( value ) => <div className='icon-style-list'>{ value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }</div>,
        },
        {
            title: 'Gambar 6',
            align: 'center',
            dataIndex: 'image6Url',
            render: ( value ) => <div className='icon-style-list'>{ value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }</div>,
        },
    ];

    return (
        <div className='main-layout'>
            <div>
                <Sidebar />
            </div>
            <div className='sub-main-layout'>
                <Card className='card-table'>
                    <p className='title-list'>UPDATE GALERI PETAL</p>
                    <Table
                        columns={ columns }
                        dataSource={ updateHouseList }
                        scroll={ { y: 340 } }
                        rowKey='_id' />
                </Card>
                <Modal
                    visible={ modalVisible }
                    onOk={ handleOk }
                    confirmLoading={ confirmLoading }
                    onCancel={ handleCancel }
                    width={ 450 }
                >
                    <div className='main-form-gallery'>
                        <div>
                            <Form.Item label="Gambar 1">
                                <div className='sub-form-gallery'>
                                    <Button
                                        icon={ <DeleteOutlined /> }
                                        type={ !imageFiles.image1 ? 'dashed' : 'danger' }
                                        onClick={ () => handleImageRemove( 'image1' ) }
                                        disabled={ !imageFiles.image1 }
                                    >
                                    </Button>
                                    <Upload
                                        beforeUpload={ ( file ) => handleImageUpload( file, 'image1' ) }
                                        showUploadList={ false }
                                    >
                                        <Button icon={ <UploadOutlined /> }>Unggah Gambar</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item label="Gambar 2">
                                <div className='sub-form-gallery'>
                                    <Button
                                        icon={ <DeleteOutlined /> }
                                        type={ !imageFiles.image2 ? 'dashed' : 'danger' }
                                        onClick={ () => handleImageRemove( 'image2' ) }
                                        disabled={ !imageFiles.image2 }
                                    >
                                    </Button>
                                    <Upload
                                        beforeUpload={ ( file ) => handleImageUpload( file, 'image2' ) }
                                        showUploadList={ false }
                                    >
                                        <Button icon={ <UploadOutlined /> }>Unggah Gambar</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item label="Gambar 3">
                                <div className='sub-form-gallery'>
                                    <Button
                                        icon={ <DeleteOutlined /> }
                                        type={ !imageFiles.image3 ? 'dashed' : 'danger' }
                                        onClick={ () => handleImageRemove( 'image3' ) }
                                        disabled={ !imageFiles.image3 }
                                    >
                                    </Button>
                                    <Upload
                                        beforeUpload={ ( file ) => handleImageUpload( file, 'image3' ) }
                                        showUploadList={ false }
                                    >
                                        <Button icon={ <UploadOutlined /> }>Unggah Gambar</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item label="Gambar 4">
                                <div className='sub-form-gallery'>
                                    <Button
                                        icon={ <DeleteOutlined /> }
                                        type={ !imageFiles.image4 ? 'dashed' : 'danger' }
                                        onClick={ () => handleImageRemove( 'image4' ) }
                                        disabled={ !imageFiles.image4 }
                                    >
                                    </Button>
                                    <Upload
                                        beforeUpload={ ( file ) => handleImageUpload( file, 'image4' ) }
                                        showUploadList={ false }
                                    >
                                        <Button icon={ <UploadOutlined /> }>Unggah Gambar</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item label="Gambar 5">
                                <div className='sub-form-gallery'>
                                    <Button
                                        icon={ <DeleteOutlined /> }
                                        type={ !imageFiles.image5 ? 'dashed' : 'danger' }
                                        onClick={ () => handleImageRemove( 'image5' ) }
                                        disabled={ !imageFiles.image5 }
                                    >
                                    </Button>
                                    <Upload
                                        beforeUpload={ ( file ) => handleImageUpload( file, 'image5' ) }
                                        showUploadList={ false }
                                    >
                                        <Button icon={ <UploadOutlined /> }>Unggah Gambar</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item label="Gambar 6">
                                <div className='sub-form-gallery'>
                                    <Button
                                        icon={ <DeleteOutlined /> }
                                        type={ !imageFiles.image6 ? 'dashed' : 'danger' }
                                        onClick={ () => handleImageRemove( 'image6' ) }
                                        disabled={ !imageFiles.image6 }
                                    >
                                    </Button>
                                    <Upload
                                        beforeUpload={ ( file ) => handleImageUpload( file, 'image6' ) }
                                        showUploadList={ false }
                                    >
                                        <Button icon={ <UploadOutlined /> }>Unggah Gambar</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default GaleriPetal;
