import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { AuthProvider } from './Authentication/contexts/authContext';
import './index.css'

ReactDOM.createRoot( document.getElementById( 'root' ) ).render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
