import { Button, Carousel, Select, Pagination, Image, Empty } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsHouse, BsLayers } from 'react-icons/bs';
import { FaWhatsapp } from 'react-icons/fa';
import { PiCertificateLight } from 'react-icons/pi';
import { Link } from 'react-router-dom';
import Header from '../../HeaderFooter/Header';
import Footer from '../../HeaderFooter/Footer';
import '../ListingStyle.css';

const ListingKavling = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ listing, setListing ] = useState( [] );
    const [ selectedLokasi, setSelectedLokasi ] = useState( 'All' );
    const [ selectedStatus, setSelectedStatus ] = useState( 'All' );
    const [ selectedHarga, setSelectedHarga ] = useState( 'All' );
    const [ selectedAngsuran, setSelectedAngsuran ] = useState( 'All' );
    const [ selectedSortAngsuran, setSelectedSortAngsuran ] = useState( 'All' );
    const [ currentPage, setCurrentPage ] = useState( 1 );
    const [ selectedSort, setSelectedSort ] = useState( 'All' );
    const [ pageSize, setPageSize ] = useState( 30 );

    useEffect( () =>
    {
        axios.get( `${ API_URL }` )
            .then( result =>
            {
                const data = result.data;
                const filterListingData = data.filter( item => item.jenis === 'Rumah Kavling' );
                setListing( filterListingData );
            } )
            .catch( err => console.log( err ) );
    }, [] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    };

    const angsuranKavling = ( property ) =>
    {
        return Math.floor( ( property.harga + property.bungaKredit - 30000000 ) / 48 );
    };

    const filteredListing = listing.filter( ( property ) =>
    {
        const lokasiFilter = selectedLokasi === 'All' || property.lokasi.includes( selectedLokasi );
        const statusFilter = selectedStatus === 'All' || property.stats === selectedStatus;
        const hargaFilter = selectedHarga === 'All' ||
            ( property.harga >= parseInt( selectedHarga.split( '-' )[ 0 ] ) &&
                property.harga <= parseInt( selectedHarga.split( '-' )[ 1 ] ) );
        const angsuranFilter = selectedAngsuran === 'All' ||
            ( angsuranKavling( property ) >= parseInt( selectedAngsuran.split( '-' )[ 0 ] ) &&
                angsuranKavling( property ) <= parseInt( selectedAngsuran.split( '-' )[ 1 ] ) );

        return lokasiFilter && statusFilter && hargaFilter && angsuranFilter;
    } );

    const soldProperties = filteredListing.filter( property => property.stats === 'Sold' );
    const unsoldProperties = filteredListing.filter( property => property.stats !== 'Sold' );

    const sortedUnsoldProperties = unsoldProperties.sort( ( a, b ) =>
    {
        if ( selectedSort === 'LowToHigh' )
        {
            return a.harga - b.harga;
        } else if ( selectedSort === 'HighToLow' )
        {
            return b.harga - a.harga;
        } else
        {
            return 0;
        }
    } );

    const sortedByAngsuran = sortedUnsoldProperties.sort( ( a, b ) =>
    {
        if ( selectedSortAngsuran === 'LowToHigh' )
        {
            return angsuranKavling( a ) - angsuranKavling( b );
        } else if ( selectedSortAngsuran === 'HighToLow' )
        {
            return angsuranKavling( b ) - angsuranKavling( a );
        } else
        {
            return 0;
        }
    } );

    const sortedAndFilteredListing = [ ...sortedByAngsuran, ...soldProperties ];

    const paginatedListing = sortedAndFilteredListing.slice( ( currentPage - 1 ) * pageSize, currentPage * pageSize );

    return (
        <div>
            <Header />
            <section className='default-section'>
                <div className='filter-listing-all'>
                    <Select
                        placeholder='Pilih Lokasi'
                        className='select-listing-all'
                        options={ [
                            { value: 'All', label: 'Semua' },
                            { value: 'Petal, Menganti', label: 'Petal' },
                            { value: 'Domas, Menganti', label: 'Domas' },
                            { value: 'Tanjung, Kedamean', label: 'Tanjung' }
                        ] }
                        onChange={ value => setSelectedLokasi( value ) }
                    />
                    <Select
                        placeholder='Pilih Status'
                        className='select-listing-all'
                        options={ [
                            { value: 'All', label: 'Semua' },
                            { value: 'Ready', label: 'Ready' },
                            { value: 'Inden', label: 'Inden' },
                            { value: 'Sold', label: 'Sold' }
                        ] }
                        onChange={ value => setSelectedStatus( value ) }
                    />
                    <Select
                        placeholder='Sort Harga'
                        className='select-listing-all'
                        options={ [
                            { value: 'All', label: 'Semua' },
                            { value: 'LowToHigh', label: 'Rendah - Tinggi' },
                            { value: 'HighToLow', label: 'Tinggi - Rendah' }
                        ] }
                        onChange={ value => setSelectedSort( value ) }
                    />
                    <Select
                        placeholder='Sort Angsuran'
                        className='select-listing-all'
                        options={ [
                            { value: 'All', label: 'Semua' },
                            { value: 'LowToHigh', label: 'Rendah - Tinggi' },
                            { value: 'HighToLow', label: 'Tinggi - Rendah' }
                        ] }
                        onChange={ value => setSelectedSortAngsuran( value ) }
                    />
                    <Select
                        placeholder='Range Harga'
                        className='select-listing-all'
                        options={ [
                            { value: 'All', label: 'Semua' },
                            { value: '0-50000000', label: '0 - 50 Juta' },
                            { value: '50000001-100000000', label: '50 Juta - 100 Juta' },
                            { value: '100000001-200000000', label: '100 Juta - 200 Juta' },
                            { value: '200000001-300000000', label: '200 Juta - 300 Juta' }
                        ] }
                        onChange={ value => setSelectedHarga( value ) }
                    />
                    <Select
                        placeholder='Range Angsuran'
                        className='select-listing-all'
                        options={ [
                            { value: 'All', label: 'Semua' },
                            { value: '0-1000000', label: '0 - 1 Juta' },
                            { value: '1000001-2000000', label: '1 Juta - 2 Juta' },
                            { value: '2000001-3000000', label: '2 Juta - 3 Juta' }
                        ] }
                        onChange={ value => setSelectedAngsuran( value ) }
                    />
                </div>
                <section className='listing-grid'>
                    { paginatedListing.length === 0 ? (
                        <div className='spinner-container'>
                            <Empty description='Property Tidak Ditemukan' />
                        </div>
                    ) : (
                        paginatedListing.map( property =>
                        {
                            const cardContent = (
                                <div className='listing-card'>
                                    <Carousel autoplay>
                                        { [ property.image1Url, property.image2Url, property.image3Url, property.image4Url, property.image5Url, property.image6Url ]
                                            .filter( url => url )
                                            .map( ( url, index ) => (
                                                <div key={ index }>
                                                    <div className='listing-img-card'>
                                                        <img
                                                            className='img-card'
                                                            src={ `${ API_URL }/${ url }` } alt='' />
                                                    </div>
                                                </div>
                                            ) ) }
                                    </Carousel>
                                    <Carousel dots={ false } autoplay>
                                        { property.stats === 'Sold' && (
                                            <div className='status-listing'>
                                                SOLD
                                            </div>
                                        ) }
                                        { property.stats !== 'Sold' && (
                                            <div className='label'>
                                                { property.label }
                                            </div>
                                        ) }
                                        <div className='blokKav'>
                                            BLOK KAVLING { property.blokKav }
                                        </div>
                                    </Carousel>
                                    <div className='listing-detail'>
                                        <div className='detail-lokasi'>
                                            <p className='jenis-properti'>{ property.jenis }</p>
                                            <p className='lokasi-properti'>{ property.lokasi }</p>
                                        </div>
                                        <p className='harga-kavling'>{ property.stats !== 'Sold' ? convertToIDR( property.harga ) : 'SOLD' }</p>
                                        <p className='angsuran-kavling'>{ convertToIDR( angsuranKavling( property ) ) }/bln</p>
                                        <div className='feature-kavling'>
                                            <div className='feature-1'><BsLayers />{ property.luastanah } M²</div>
                                            <div className='feature-3'><BsHouse />{ property.luasbangunan }M²</div>
                                            <div className='feature-2'><PiCertificateLight /> Petok D</div>
                                        </div>
                                        <div className='chat-brosur'>
                                            <Link to='https://wa.me/6282336931024'>
                                                <button className='chat-btn'>
                                                    Hubungi Sekarang
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                            return property.stats === 'Sold' ? (
                                <div key={ property._id }>{ cardContent }</div>
                            ) : (
                                <Link to={ `/property-details/${ property._id }` } key={ property._id }>
                                    { cardContent }
                                </Link>
                            );
                        } )
                    ) }
                </section>
                <div className='pagination-listing'>
                    <Pagination
                        current={ currentPage }
                        pageSize={ pageSize }
                        total={ sortedAndFilteredListing.length }
                        showTotal={ ( total ) => `Total ${ total } property` }
                        onChange={ ( page, pageSize ) =>
                        {
                            setCurrentPage( page );
                            setPageSize( pageSize );
                        } }
                    />
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ListingKavling;
