import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../PropertyData.css';
import { Button, Flex, Form, Input, InputNumber, Select, notification } from 'antd'; // Import notification dari antd
import Sidebar from '../Sidebar';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

const UpdateProperti = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const { id } = useParams();
    const [ judul, setJudul ] = useState( '' );
    const [ stats, setStats ] = useState( '' );
    const [ harga, setHarga ] = useState( '' );
    const [ jenis, setJenis ] = useState( 'Tanah Kavling' );
    const [ label, setLabel ] = useState( '' );
    const [ deskripsi, setDeskripsi ] = useState( '' );
    const [ minDPInhouse, setMinDPInhouse ] = useState( '' );
    const [ minDPKUR, setMinDPKUR ] = useState( '' );
    const [ lokasi, setLokasi ] = useState( '' );
    const [ blokKav, setBlokKav ] = useState( '' );
    const [ hadiah, setHadiah ] = useState( '' );
    const [ alamat, setAlamat ] = useState( '' );
    const [ luastanah, setLuasTanah ] = useState( '' );
    const [ jalankavling, setJalanKavling ] = useState( '' );
    const [ cashKeras, setCashKeras ] = useState( '' );
    const [ cashTempo, setCashTempo ] = useState( '' );
    const [ kredit, setKredit ] = useState( '' );
    const [ tenorMaks, setTenorMaks ] = useState();
    const [ bungaKredit, setBungaKredit ] = useState( '' );
    const [ bungaKUR, setBungaKUR ] = useState( '' );
    const [ kur, setKur ] = useState( '' );
    const navigate = useNavigate()
    useEffect( () =>
    {
        axios.get( `${ API_URL }/getProperty/` + id )
            .then( result =>
            {
                const {
                    judul,
                    stats,
                    harga,
                    jenis,
                    label,
                    deskripsi,
                    minDPInhouse,
                    minDPKUR,
                    lokasi,
                    blokKav,
                    hadiah,
                    alamat,
                    luastanah,
                    jalankavling,
                    cashKeras,
                    cashTempo,
                    kredit,
                    tenorMaks,
                    bungaKredit,
                    bungaKUR,
                    kur,
                } = result.data;
                setJudul( judul );
                setStats( stats );
                setHarga( harga );
                setJenis( jenis );
                setLabel( label );
                setMinDPKUR( minDPKUR );
                setDeskripsi( deskripsi );
                setMinDPInhouse( minDPInhouse );
                setLokasi( lokasi );
                setBlokKav( blokKav );
                setHadiah( hadiah );
                setAlamat( alamat );
                setLuasTanah( luastanah );
                setJalanKavling( jalankavling );
                setCashKeras( cashKeras );
                setCashTempo( cashTempo );
                setKredit( kredit );
                setTenorMaks( tenorMaks );
                setBungaKredit( bungaKredit );
                setBungaKUR( bungaKUR );
                setKur( kur );
            } )
            .catch( err => console.log( err ) );
    }, [] );
    const handleUpdate = () =>
    {
        axios.put( `${ API_URL }/updateProperty/` + id, {
            judul,
            stats,
            harga,
            jenis,
            label,
            deskripsi,
            minDPInhouse,
            minDPKUR,
            lokasi,
            blokKav,
            hadiah,
            alamat,
            luastanah,
            jalankavling,
            cashKeras,
            cashTempo,
            kredit,
            tenorMaks,
            bungaKredit,
            bungaKUR,
            kur,
        } )
            .then( result =>
            {
                console.log( result );
                openNotification( 'topRight' );
                handleNavigateBack();
            } )
            .catch( err =>
            {
                console.log( err );
                openNotification( 'topRight' );
            } );
    }
    const handleCheckboxChange = ( event ) =>
    {
        const { value, checked } = event.target;
        switch ( value )
        {
            case 'cashkeras':
                setCashKeras( checked ? 'cashkeras' : '' );
                break;
            case 'cashtempo':
                setCashTempo( checked ? 'cashtempo' : '' );
                break;
            case 'kredit':
                setKredit( checked ? 'kredit' : '' );
                break;
            case 'kur':
                setKur( checked ? 'kur' : '' );
                break;
            default:
                break;
        }
    };
    const options = [
        { value: 'cashkeras', label: 'Cash Keras', emoji: '💵' },
        { value: 'cashtempo', label: 'Cash Tempo', emoji: '🕒' },
        { value: 'kredit', label: 'Kredit', emoji: '💳' },
        { value: 'kur', label: 'KUR', emoji: '🏦' },
    ];
    const openNotification = ( placement ) =>
    {
        notification.info( {
            message: `Notification ${ placement }`,
            description:
                'Properti Berhasil Di Update',
            placement,
        } );
    };
    const handleNavigateBack = () =>
    {
        navigate( -1 );
    };
    const handleChangeAlamat = ( value ) =>
    {
        setAlamat( value );
    };
    const handleChangeLokasi = ( value ) =>
    {
        setLokasi( value );
    };
    const handleChangeStatus = ( value ) =>
    {
        setStats( value );
    };
    const handleChangeJenis = ( value ) =>
    {
        setJenis( value );
    };
    const handleChangeTenor = ( value ) =>
    {
        setTenorMaks( value );
    };

    return (
        <Flex>
            <section>
                <Sidebar />
            </section>
            <section className='main-edit-section'>
                <div title='Update Property'>
                    <Form onFinish={ handleUpdate } autoComplete='off'>
                        <section>
                            <Flex gap={ 20 } justify='center' align='center'>
                                <div>
                                    <div className='div-input'>
                                        <label htmlFor='blokKav'>Blok Kavling</label>
                                        <Input
                                            placeholder='Masukan Blok Kavling'
                                            value={ blokKav }
                                            className='input-form'
                                            onChange={ ( e ) => setBlokKav( e.target.value ) }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='judul'>Judul</label>
                                        <Input
                                            placeholder='Masukan Judul Property'
                                            value={ judul }
                                            className='input-form'
                                            onChange={ ( e ) => setJudul( e.target.value ) }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='stats'>Status</label>
                                        <Select
                                            onChange={ handleChangeStatus }
                                            value={ stats }
                                            loading
                                            options={ [
                                                { value: 'Ready', label: 'Ready' },
                                                { value: 'Inden', label: 'Inden' },
                                                { value: 'Sold', label: 'Sold' },
                                            ] }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='lokasi'>Lokasi</label>
                                        <Select
                                            onChange={ handleChangeLokasi }
                                            value={ lokasi }
                                            loading
                                            options={ [
                                                { value: 'Domas, Menganti', label: 'Domas' },
                                                { value: 'Petal, Menganti', label: 'Petal' },
                                                { value: 'Tanjung, Kedamean', label: 'Kedamean' },
                                            ] }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='alamat'>Alamat Properti</label>
                                        <Select
                                            value={ alamat }
                                            onChange={ handleChangeAlamat }
                                            loading
                                            options={ [
                                                { value: 'Desa Domas, Kec. Menganti, Kab. Gresik', label: 'Domas' },
                                                { value: 'Dusun Petal, Kec. Menganti, Kab. Gresik', label: 'Petal' },
                                                { value: 'Desa Tanjung, Kec. Kedamean, Kab. Gresik', label: 'Kedamean' },
                                            ] }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='div-input'>
                                        <label htmlFor='jenis'>Jenis Properti</label>
                                        <Select
                                            onChange={ handleChangeJenis }
                                            loading
                                            value={ jenis }
                                            options={ [
                                                { value: 'Rumah Kavling', label: 'Rumah Kavling' },
                                                { value: 'Tanah Kavling', label: 'Tanah Kavling' },
                                            ] }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='luastanah'>Luas Tanah</label>
                                        <Input
                                            placeholder='Masukkan Luas Tanah'
                                            className='input-form'
                                            value={ luastanah }
                                            onChange={ ( e ) => setLuasTanah( e.target.value ) }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='jalankavling'>Jalan Kavling</label>
                                        <Input
                                            placeholder='Masukkan Lebar Jalan'
                                            className='input-form'
                                            value={ jalankavling }
                                            onChange={ ( e ) => setJalanKavling( e.target.value ) }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='harga'>Harga</label>
                                        <InputNumber
                                            placeholder='Masukkan Harga Property'
                                            className='input-form'
                                            value={ harga }
                                            onChange={ setHarga }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='minDPInhouse'>Min DP Inhouse</label>
                                        <InputNumber
                                            placeholder='Masukkan Min DP Inhouse'
                                            value={ minDPInhouse }
                                            className='input-form'
                                            onChange={ setMinDPInhouse }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='div-input'>
                                        <label htmlFor='KUR'>Min DP KUR</label>
                                        <InputNumber
                                            placeholder='Masukkan Min DP KUR'
                                            value={ minDPKUR }
                                            className='input-form'
                                            onChange={ setMinDPKUR }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='tenorMaks'>Tenor Maksimal</label>
                                        <Select
                                            placeholder='Tentukan Tenor Maks'
                                            value={ tenorMaks }
                                            loading
                                            onChange={ handleChangeTenor }
                                            options={ [
                                                { value: 1, label: '1 Tahun' },
                                                { value: 2, label: '2 Tahun' },
                                                { value: 3, label: '3 Tahun' },
                                                { value: 4, label: '4 Tahun' },
                                                { value: 5, label: '5 Tahun' },
                                            ] }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='bungaKredit'>Bunga Kredit</label>
                                        <InputNumber
                                            value={ bungaKredit }
                                            placeholder='Tambahkan Bunga Kredit'
                                            className='input-form'
                                            onChange={ setBungaKredit }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='bungaKUR'>Bunga KUR</label>
                                        <InputNumber
                                            value={ bungaKUR }
                                            placeholder='Tambahkan Bunga KUR'
                                            className='input-form'
                                            onChange={ setBungaKUR }
                                        />
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='label'>Label</label>
                                        <Input
                                            value={ label }
                                            placeholder='Tambahkan Label'
                                            className='input-form'
                                            onChange={ ( e ) => setLabel( e.target.value ) }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='div-input'>
                                        <label htmlFor='hadiah'>Hadiah</label>
                                        <Input
                                            value={ hadiah }
                                            placeholder='Tambahkan Hadiah'
                                            className='input-form'
                                            onChange={ ( e ) => setHadiah( e.target.value ) }
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor='pilihMetodePembayaran'>Pilih Metode Pembayaran</label>
                                        <div className='payments-method'>
                                            { options.map( option => (
                                                <div key={ option.value }>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value={ option.value }
                                                            checked={ option.value === 'cashkeras' ? cashKeras !== '' : option.value === 'cashtempo' ? cashTempo !== '' : option.value === 'kredit' ? kredit !== '' : kur !== '' }
                                                            onChange={ handleCheckboxChange }
                                                        />
                                                        <span role="img" aria-label={ option.label }>{ option.emoji }</span> { option.label }
                                                    </label>
                                                </div>
                                            ) ) }
                                        </div>
                                    </div>
                                    <div className='div-input'>
                                        <label htmlFor='deskripsi'>Deskripsi</label>
                                        <TextArea
                                            id='deskripsi'
                                            className='input-form'
                                            rows='3'
                                            placeholder='Masukkan deskripsi property'
                                            value={ deskripsi }
                                            onChange={ ( e ) => setDeskripsi( e.target.value ) }
                                        />
                                    </div>
                                </div>
                            </Flex>
                        </section>
                        <Flex justify='center' gap='middle'>
                            <Button
                                onClick={ handleNavigateBack }>
                                Kembali
                            </Button>
                            <Button
                                type='primary'
                                htmlType='submit'>
                                Update
                            </Button>
                        </Flex>
                    </Form>
                </div>
            </section>
        </Flex >
    );
};

export default UpdateProperti;
