import { Card, Statistic, Flex, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import axios from 'axios';

const PropertiTerjual = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ totalPriceSold, setTotalPriceSold ] = useState( 0 );
    const [ totalSoldCount, setTotalSoldCount ] = useState( 0 );
    const [ blokKav, setBlokKav ] = useState( '' );
    const [ harga, setHarga ] = useState( '' );
    const [ stats, setStats ] = useState( '' );
    const [ jenis, setJenis ] = useState( '' );
    const [ totalPriceInden, setTotalPriceInden ] = useState( 0 );
    const [ totalPriceReady, setTotalPriceReady ] = useState( 0 );
    const [ totalIndenCount, setTotalIndenCount ] = useState( 0 );
    const [ totalReadyCount, setTotalReadyCount ] = useState( 0 );
    const [ rumahKavling, setRumahKavling ] = useState( [] );
    const [ tanahKavling, setTanahKavling ] = useState( [] );


    useEffect( () =>
    {
        axios
            .get( `${ API_URL }`, {
                params: {
                    jenis,
                    stats,
                    harga,
                    blokKav,
                },
            } )
            .then( ( result ) =>
            {
                const indenProperties = result.data.filter( ( property ) => property.stats === 'Inden' );
                const readyProperties = result.data.filter( ( property ) => property.stats === 'Ready' );
                const soldProperties = result.data.filter( ( property ) => property.stats === 'Sold' );


                const totalPriceInden = indenProperties.reduce( ( sum, property ) => sum + property.harga, 0 );
                const totalPriceReady = readyProperties.reduce( ( sum, property ) => sum + property.harga, 0 );
                const totalPriceSold = soldProperties.reduce( ( sum, property ) => sum + property.harga, 0 );


                const totalIndenCount = indenProperties.length;
                const totalReadyCount = readyProperties.length;

                setTotalPriceInden( totalPriceInden );
                setTotalPriceReady( totalPriceReady );
                setTotalIndenCount( totalIndenCount );
                setTotalReadyCount( totalReadyCount );
                setTotalPriceSold( totalPriceSold );

                const rumahKavling = result.data.filter( ( property ) => property.jenis === 'Rumah Kavling' );
                const tanahKavling = result.data.filter( ( property ) => property.jenis === 'Tanah Kavling' );

                setRumahKavling( rumahKavling );
                setTanahKavling( tanahKavling );
            } )
            .catch( ( err ) => console.log( err ) );
    }, [ jenis, stats, harga, blokKav ] );

    const calculateStatistics = ( properties ) =>
    {
        const indenProperties = properties.filter( ( property ) => property.stats === 'Inden' );
        const readyProperties = properties.filter( ( property ) => property.stats === 'Ready' );
        const soldProperties = properties.filter( ( property ) => property.stats === 'Sold' )
        const soldRumahKavling = properties.filter( ( property ) => property.jenis === 'Rumah Kavling' && property.stats === 'Sold' );
        const soldTanahKavling = properties.filter( ( property ) => property.jenis === 'Tanah Kavling' && property.stats === 'Sold' );


        const totalPriceInden = indenProperties.reduce( ( sum, property ) => sum + property.harga, 0 );
        const totalPriceReady = readyProperties.reduce( ( sum, property ) => sum + property.harga, 0 );
        const totalPriceSold = soldProperties.reduce( ( sum, property ) => sum + property.harga, 0 )
        const totalPriceSoldRumahKavling = soldRumahKavling.reduce( ( sum, property ) => sum + property.harga, 0 );
        const totalPriceSoldTanahKavling = soldTanahKavling.reduce( ( sum, property ) => sum + property.harga, 0 );


        const totalIndenCount = indenProperties.length;
        const totalReadyCount = readyProperties.length;
        const totalSoldCount = soldProperties.length;

        return {
            totalPriceSoldTanahKavling,
            totalPriceSold,
            totalPriceSoldRumahKavling,
            totalPriceInden,
            totalPriceReady,
            totalIndenCount,
            totalReadyCount,
            totalSoldCount
        };
    };

    const rumahKavlingStats = calculateStatistics( rumahKavling );
    const tanahKavlingStats = calculateStatistics( tanahKavling );
    const { totalPriceSoldRumahKavling } = calculateStatistics( rumahKavling );
    const { totalPriceSoldTanahKavling } = calculateStatistics( tanahKavling );


    return (
        <Flex>
            <section>
                <Sidebar />
            </section>
            <section className='main-list-statistic'>
                <Card className='main-card'>
                    <Collapse className='bg-collapse' defaultActiveKey='1' accordion>

                        {/*Global Statistik*/ }

                        <Collapse.Panel className='head-panel' header='Global Statistik' key='1'>
                            <Collapse accordion defaultActiveKey='1'>
                                <Collapse.Panel header='Total Unit Terjual' key='1'>
                                    <Flex gap={ 10 }>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Total Pendapatan'
                                                value={ totalPriceSold }
                                                precision={ 2 }
                                                valueStyle={ { color: '#3f8600' } }
                                                prefix='Rp'
                                                suffix=''
                                            />
                                        </Card>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Total Unit Terjual'
                                                value={ totalSoldCount }
                                                valueStyle={ { color: '#3f8600' } }
                                                suffix='unit'
                                            />
                                        </Card>
                                    </Flex>
                                </Collapse.Panel>
                                <Collapse.Panel header='Total Unit Belum Terjual' key='2'>
                                    <Flex gap={ 10 }>
                                        <Card className='statistic-card'>
                                            <Statistic

                                                title='Rencana Pendapatan'
                                                value={ totalPriceInden + totalPriceReady }
                                                precision={ 2 }
                                                valueStyle={ { color: '#3f8600' } }
                                                prefix='Rp'
                                                suffix=''
                                            />
                                        </Card>
                                        <Card className='statistic-card'>
                                            <Statistic

                                                title='Total Unit Belum Terjual'
                                                value={ totalIndenCount + totalReadyCount }
                                                valueStyle={ { color: '#3f8600' } }
                                                suffix='unit'
                                            />
                                        </Card>
                                    </Flex>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>

                        {/*Tanah Kavling*/ }

                        <Collapse.Panel className='head-panel' header="Tanah Kavling" key="2">
                            <Collapse defaultActiveKey="1" accordion>
                                <Collapse.Panel header="Terjual" key="1">
                                    <Flex gap={ 10 }>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Pendapatan Kavling Terjual'
                                                value={ totalPriceSoldTanahKavling }
                                                precision={ 2 }
                                                valueStyle={ { color: '#3f8600' } }
                                                prefix='Rp'
                                                suffix=''
                                            />
                                        </Card>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Unit Kavling Terjual'
                                                value={ tanahKavlingStats.totalSoldCount }
                                                valueStyle={ { color: '#3f8600' } }
                                                suffix='unit'
                                            />
                                        </Card>
                                    </Flex>
                                </Collapse.Panel>
                                <Collapse.Panel header='Belum Terjual' key='2'>
                                    <Flex gap={ 10 }>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Rencana Pendapatan'
                                                value={ tanahKavlingStats.totalPriceReady }
                                                precision={ 2 }
                                                valueStyle={ { color: '#3f8600' } }
                                                prefix='Rp'
                                                suffix=''
                                            />
                                        </Card>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Unit Kavling Belum Terjual'
                                                value={ tanahKavlingStats.totalReadyCount }
                                                valueStyle={ { color: '#3f8600' } }
                                                suffix='unit'
                                            />
                                        </Card>
                                    </Flex>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>

                        {/*Rumah Kavling*/ }

                        <Collapse.Panel className='head-panel' header='Rumah Kavling' key='3'>
                            <Collapse defaultActiveKey='1' accordion>
                                <Collapse.Panel header='Terjual' key='1'>
                                    <Flex gap={ 10 }>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Pendapatan Rumah Terjual'
                                                value={ totalPriceSoldRumahKavling }
                                                precision={ 2 }
                                                valueStyle={ { color: '#3f8600' } }
                                                prefix='Rp'
                                                suffix=''
                                            />
                                        </Card>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Unit Rumah Terjual'
                                                value={ rumahKavlingStats.totalSoldCount }
                                                valueStyle={ { color: '#3f8600' } }
                                                suffix='unit'
                                            />
                                        </Card>
                                    </Flex>
                                </Collapse.Panel>
                                <Collapse.Panel header='Total Inden' key='2'>
                                    <Flex gap={ 10 }>
                                        <Card className='statistic-card'>
                                            <Statistic
                                                title='Rencana Pendapatan'
                                                value={ rumahKavlingStats.totalPriceInden }
                                                precision={ 2 }
                                                valueStyle={ { color: '#3f8600' } }
                                                prefix='Rp'
                                                suffix=''
                                            />
                                        </Card>
                                        <Card className='statistic-card'>
                                            <Statistic

                                                title='Unit Rumah Inden'
                                                value={ rumahKavlingStats.totalIndenCount }
                                                valueStyle={ { color: '#3f8600' } }
                                                suffix='unit'
                                            />
                                        </Card>
                                    </Flex>
                                </Collapse.Panel>
                                <Collapse.Panel header='Total Ready' key='3'>
                                    <Flex gap={ 10 }>
                                        <Card className='statistic-card'>
                                            <Statistic

                                                title='Rencana Pendapatan'
                                                value={ rumahKavlingStats.totalPriceReady }
                                                precision={ 2 }
                                                valueStyle={ { color: '#3f8600' } }
                                                prefix='Rp'
                                                suffix=''
                                            />
                                        </Card>
                                        <Card className='statistic-card'>
                                            <Statistic

                                                title='Unit Rumah Ready'
                                                value={ rumahKavlingStats.totalReadyCount }
                                                valueStyle={ { color: '#3f8600' } }
                                                suffix='unit'
                                            />
                                        </Card>
                                    </Flex>
                                </Collapse.Panel>
                            </Collapse>
                        </Collapse.Panel>


                    </Collapse>
                </Card>
            </section>
        </Flex>
    );
};

export default PropertiTerjual;
