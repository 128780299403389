import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; // Perhatikan penggunaan useParams
import { PiCertificateLight } from "react-icons/pi";
import { PiRoadHorizonLight } from "react-icons/pi";
import { BsLayers, BsHouseCheck, BsHouse } from "react-icons/bs";
import { MdOutlineFlood } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { Button, Carousel, Divider, Empty } from 'antd'
import '../Listing/ListingStyle.css'
import axios from 'axios';
import Header from '../HeaderFooter/Header';
import Footer from '../HeaderFooter/Footer';
import '../Listing/spinner.css'
const Home = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ listing, setListing ] = useState( [] );

    useEffect( () =>
    {
        axios.get( `${ API_URL }` )
            .then( result =>
            {
                setListing( result.data );
            } )
            .catch( err =>
            {
                console.log( err );
            } );
    }, [] ); const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', {
            style: 'currency',
            currency: 'IDR',
            maximumFractionDigits: 0
        } ).format( value );
    }
    const rumahkavling = listing
        .filter( rumah => rumah.jenis === 'Rumah Kavling' && rumah.stats !== 'Sold' && rumah.label )
        .slice( 0, 30 );

    const tanahkavling = listing
        .filter( tanah => tanah.jenis === 'Tanah Kavling' && tanah.stats !== 'Sold' && tanah.label )
        .slice( 0, 30 );

    const angsuranKavling = ( tanah ) =>
    {
        return Math.floor( ( tanah.harga + tanah.bungaKredit - 30000000 ) / 48 );
    }
    const uniqueLocations = [ ...new Set( listing.map( item => item.lokasi ) ) ];
    const images = [
        listing.image1Url,
        listing.image2Url,
        listing.image3Url,
        listing.image4Url,
        listing.image5Url,
        listing.image6Url,
    ];
    return (
        <div>
            <Header />
            <section className='default-section'>
                {/* Listing Kavling */ }
                <div className='home-listing'>
                    <div className='filter-div'>
                        <div>
                            <h1 className='filter-listing-title'>List Tanah Kavling</h1>
                            <h2 className='filter-listing-subtitle'>Temukan berbagai macam tanah kavling terbaik disini</h2>
                        </div>
                        <Link to='/listing-kavling'>
                            <Button>Lihat Semua</Button>
                        </Link>
                    </div>
                    <div className="listing-grid">
                        { tanahkavling.length === 0 ? (
                            <div className='spinner-container'>
                                <Empty description='Property Tidak Ditemukan' />
                            </div>
                        ) : (
                            <>
                                { tanahkavling.map( tanah => (
                                    <Link to={ `/tanah-kavling/${ tanah._id }` } key={ tanah._id }>
                                        <div className='listing-card'>
                                            <Carousel autoplay dots={ false }>
                                                { [
                                                    tanah.image1Url ? `${ API_URL }/${ tanah.image1Url }` : null,
                                                    tanah.image2Url ? `${ API_URL }/${ tanah.image2Url }` : null,
                                                    tanah.image3Url ? `${ API_URL }/${ tanah.image3Url }` : null,
                                                    tanah.image4Url ? `${ API_URL }/${ tanah.image4Url }` : null,
                                                    tanah.image5Url ? `${ API_URL }/${ tanah.image5Url }` : null,
                                                    tanah.image6Url ? `${ API_URL }/${ tanah.image6Url }` : null,
                                                ].filter( url => url ).map( ( url, index ) => (
                                                    <div key={ index }>
                                                        <div className='listing-img-card'>
                                                            <img
                                                                className='img-card'
                                                                src={ url } alt='Unavailable' />
                                                        </div>
                                                    </div>
                                                ) ) }
                                            </Carousel>
                                            <Carousel dots={ false } autoplay>
                                                { tanah.stats === 'Sold' && (
                                                    <div className='status-listing'>
                                                        SOLD
                                                    </div>
                                                ) }
                                                { tanah.stats !== 'Sold' && (
                                                    <div className='label'>
                                                        { tanah.label }
                                                    </div>
                                                ) }
                                                <div className='blokKav'>
                                                    BLOK KAVLING { tanah.blokKav }
                                                </div>
                                            </Carousel>
                                            <div className='listing-detail'>
                                                <div className='detail-lokasi'>
                                                    <p className='jenis-properti'>{ tanah.jenis }</p>
                                                    <p className='lokasi-properti'>{ tanah.lokasi }</p>
                                                </div>
                                                <p className='harga-kavling'>{ convertToIDR( tanah.harga ) }</p>
                                                <p className='angsuran-kavling'>{ convertToIDR( angsuranKavling( tanah ) ) } / bln</p>
                                                <div className='feature-kavling'>
                                                    <div className='feature-1'><BsLayers />{ tanah.luastanah } M²</div>
                                                    <div className='feature-2'><PiCertificateLight /> Petok D</div>
                                                </div>
                                                <div className='chat-brosur'>
                                                    <Link to='https://wa.me/6282336931024'>
                                                        <button className='chat-btn'>
                                                            <FaWhatsapp />Hubungi Sekarang
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ) ) }
                            </>
                        ) }
                    </div>
                </div>
                <Divider />

                <div className='filter-location-title'>
                    <h1 className='filter-listing-title'>Cari Hunian Menurut Lokasi</h1>
                </div>
                <div className='location-listing'>
                    <div className="listing-location">
                        { uniqueLocations.length === 0 ? (
                            <div className='spinner-container'>
                                <Empty description='Property Tidak Ditemukan' />
                            </div>
                        ) : (
                            <>
                                {
                                    uniqueLocations.map( ( lokasi, index ) => (
                                        <div className='listing-card-lokasi'>
                                            <img
                                                className='lokasi-list-img'
                                                src={ `${ API_URL }/${ listing.find( item => item.lokasi === lokasi ).image2Url }` } alt='Unavailable' />
                                            <div>
                                                <p className='title-lokasi'>{ lokasi }</p>
                                                <span className='infolokasi-1'>
                                                    { lokasi === "Petal, Menganti" && (
                                                        <>
                                                            <PiRoadHorizonLight /> 500M Dari Jalan Raya
                                                        </>
                                                    ) }
                                                    { lokasi === "Domas, Menganti" && (
                                                        <>
                                                            <PiRoadHorizonLight /> 800M Dari Jalan Raya
                                                        </>
                                                    ) }
                                                    { lokasi === "Tanjung, Kedamean" && (
                                                        <>
                                                            <PiRoadHorizonLight /> 1KM Dari Jalan Raya
                                                        </>
                                                    ) }
                                                </span>
                                                <p className='infolokasi-2'>
                                                    { ( lokasi === "Petal, Menganti" || lokasi === "Domas, Menganti" || lokasi === "Tanjung, Kedamean" ) && (
                                                        <>
                                                            <MdOutlineFlood /> Bebas Banjir
                                                        </>
                                                    ) }
                                                </p>
                                                <p className='infolokasi-3'>
                                                    { lokasi === "Petal, Menganti" && (
                                                        <>
                                                            <BsHouseCheck />80% Pembangunan Rumah.
                                                        </>
                                                    ) }
                                                    { lokasi === "Domas, Menganti" && (
                                                        <>
                                                            <BsHouseCheck />Lokasi Dekat Perumahan.
                                                        </>
                                                    ) }
                                                    { lokasi === "Tanjung, Kedamean" && (
                                                        <>
                                                            <BsHouseCheck />Harga Murah Lokasi Mewah.
                                                        </>
                                                    ) }
                                                </p>
                                            </div>
                                        </div>
                                    ) )
                                }
                            </>
                        ) }
                    </div>
                </div>

                <Divider />
                {/* Listing Rumah */ }
                <div className='home-listing'>
                    <div className='filter-div'>
                        <div>
                            <h1 className='filter-listing-title'>List Rumah Kavling</h1>
                            <h2 className='filter-listing-subtitle'>Temukan berbagai macam rumah kavling terbaik disini</h2>
                        </div>
                        <Link to='/listing-rumah'>
                            <Button>Lihat Semua</Button>
                        </Link>
                    </div>
                    <div className='listing-grid'>
                        { rumahkavling.length === 0 ? (
                            <div className='spinner-container'>
                                <Empty description='Property Tidak Ditemukan' />
                            </div>
                        ) : (
                            <>
                                { rumahkavling.map( rumah => (
                                    <Link to={ `/rumah-kavling/${ rumah._id }` } key={ rumah._id }>
                                        <div className='listing-card' size='small'>
                                            <Carousel autoplay>
                                                { [
                                                    rumah.image1Url ? `${ API_URL }/${ rumah.image1Url }` : null,
                                                    rumah.image2Url ? `${ API_URL }/${ rumah.image2Url }` : null,
                                                    rumah.image3Url ? `${ API_URL }/${ rumah.image3Url }` : null,
                                                    rumah.image4Url ? `${ API_URL }/${ rumah.image4Url }` : null,
                                                    rumah.image5Url ? `${ API_URL }/${ rumah.image5Url }` : null,
                                                    rumah.image6Url ? `${ API_URL }/${ rumah.image6Url }` : null,
                                                ].filter( url => url ).map( ( url, index ) => (
                                                    <div key={ index } className='relative'>
                                                        <div className='listing-img-card'>
                                                            <img
                                                                className='img-card'
                                                                src={ url } alt='Unavailable' />
                                                        </div>
                                                    </div>
                                                ) ) }
                                            </Carousel>
                                            <Carousel dots={ false } autoplay>
                                                { rumah.stats === 'Sold' && (
                                                    <div className='status-listing'>
                                                        SOLD
                                                    </div>
                                                ) }
                                                { rumah.stats === 'Sold' && rumah.label && (
                                                    <div className='label'>
                                                        { rumah.label }
                                                    </div>
                                                ) }
                                                <div className='blokKav'>
                                                    BLOK KAVLING { rumah.blokKav }
                                                </div>
                                            </Carousel>
                                            <div className='listing-detail'>
                                                <div className='detail-lokasi'>
                                                    <p className='jenis-properti'>{ rumah.jenis }</p>
                                                    <p className='lokasi-properti'>{ rumah.lokasi }</p>
                                                </div>
                                                <p className='harga-kavling'>{ convertToIDR( rumah.harga ) }</p>
                                                <p className='angsuran-kavling'>{ convertToIDR( angsuranKavling( rumah ) ) } / bln</p>
                                                <div className='feature-kavling'>
                                                    <div className='feature-1'><BsLayers />{ rumah.luastanah } M²</div>
                                                    <div className='feature-3'><BsHouse />{ rumah.luasbangunan }M²</div>
                                                    <div className='feature-2'><PiCertificateLight /> Petok D</div>
                                                </div>
                                                <div className='chat-brosur'>
                                                    <Link to='https://wa.me/6282336931024'>
                                                        <button className='chat-btn'>
                                                            <FaWhatsapp />Hubungi Sekarang
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ) ) }
                            </>
                        ) }
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Home;
