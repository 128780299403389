import React from "react";
import './headerfooter.css';
import headerImage1 from './header1.png'
import { Link } from "react-router-dom";
const Header = () =>
{
    return (
        <section className="main-section">
            <img className="gambar-header" src={ headerImage1 } alt='Header-1' />
            <div className="div-title-header">
                <div>
                    <Link className="title-hashtag" to='/'>
                        #CariPropertiyaDisini
                    </Link>
                    <p className="text-header">Kami percaya dalam membantu Anda menemukan properti yang tepat,</p>
                    <p className="text-header">di lokasi yang tepat, dan dengan harga yang tepat di Menganti dan sekitarnya.</p>
                    <p className="text-header">Jika Anda tertarik untuk membeli Properti, lihatlah apa yang kami tawarkan.</p>
                    <div>
                        <div className='div-header-li'>
                            <Link to='/listing-rumah'>
                                <p className='header-li'>Rumah Kavling</p>
                            </Link>
                            <Link to='/listing-kavling'>
                                <p className='header-li'>Tanah Kavling</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Header;
