import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Modal, notification, InputNumber, Table, Flex, Form, Card } from 'antd';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import axios from 'axios';
import Sidebar from '../Sidebar';
import '../PropertyData.css';

const UpdateRuangan = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ updateHouseList, setUpdateHouseList ] = useState( [] );
    const [ selectedHouse, setSelectedHouse ] = useState( null );
    const [ blokKav, setBlokKav ] = useState( '' );
    const [ luasbangunan, setLuasBangunan ] = useState( '' );
    const [ checkedList, setCheckedList ] = useState( [] );
    const [ confirmLoading, setConfirmLoading ] = useState( false );
    const [ modalVisible, setModalVisible ] = useState( false );
    const [ kamartidur, setKamarTidur ] = useState( 0 );
    const [ kamarmandi, setKamarMandi ] = useState( 0 );

    const CheckboxGroup = Checkbox.Group;

    useEffect( () =>
    {
        axios.get( `${ API_URL }` )
            .then( result =>
            {
                const data = result.data;
                const filteredGambarKavling = data.filter( item => item.lokasi === 'Domas, Menganti' );
                setUpdateHouseList( filteredGambarKavling );
            } )
            .catch( err => console.log( err ) );
    }, [] );

    const showModal = ( house ) =>
    {
        setSelectedHouse( house );
        setBlokKav( house.blokKav );
        setLuasBangunan( house.luasbangunan );
        setCheckedList( [
            house.ruangmakan && 'ruangmakan',
            house.kamarmandi && 'kamarmandi',
            house.kamartidur && 'kamartidur',
            house.carport && 'carport',
            house.dapur && 'dapur',
            house.ruangtamu && 'ruangtamu',
            house.taman && 'taman',
            house.ruangterbuka && 'ruangterbuka',
            house.kanopi && 'kanopi',
            house.pagar && 'pagar'
        ].filter( Boolean ) );
        setKamarTidur( house.kamartidur );
        setKamarMandi( house.kamarmandi );
        setModalVisible( true );
    };

    const handleOk = async () =>
    {
        setConfirmLoading( true );
        try
        {
            const updatedHouse = {
                blokKav,
                luasbangunan,
                checkedList,
                kamartidur,
                kamarmandi,
                ruangmakan: checkedList.includes( 'ruangmakan' ) ? 1 : 0,
                carport: checkedList.includes( 'carport' ) ? 1 : 0,
                dapur: checkedList.includes( 'dapur' ) ? 1 : 0,
                ruangtamu: checkedList.includes( 'ruangtamu' ) ? 1 : 0,
                taman: checkedList.includes( 'taman' ) ? 1 : 0,
                ruangterbuka: checkedList.includes( 'ruangterbuka' ) ? 1 : 0,
                kanopi: checkedList.includes( 'kanopi' ) ? 1 : 0,
                pagar: checkedList.includes( 'pagar' ) ? 1 : 0,
            };

            await axios.put( `${ API_URL }/updateProperty/${ selectedHouse._id }`, updatedHouse );
            notification.success( { message: 'Success', description: 'Property updated successfully' } );
            setUpdateHouseList( updateHouseList.map( h => h._id === selectedHouse._id ? { ...h, ...updatedHouse } : h ) );
            setModalVisible( false );
        } catch ( error )
        {
            notification.error( { message: 'Error', description: 'Failed to update property' } );
        } finally
        {
            setConfirmLoading( false );
        }
    };

    const handleCancel = () =>
    {
        setModalVisible( false );
    };

    const onChange = ( list ) =>
    {
        setCheckedList( list );
    };

    const onCheckAllChange = ( e ) =>
    {
        const newCheckedList = e.target.checked ? [
            'ruangmakan',
            'carport',
            'dapur',
            'ruangtamu',
            'taman',
            'ruangterbuka',
            'kanopi',
            'pagar',
        ] : [];
        setCheckedList( newCheckedList );
    };

    const checkAll = checkedList.length === 10;
    const indeterminate = checkedList.length > 0 && checkedList.length < 10;

    const renderTableData = ( value, unit ) =>
    {
        return value ? `${ value } ${ unit }` : '';
    };

    const columns = [
        {
            title: 'Tindakan',
            dataIndex: 'actions',
            render: ( text, record ) => (
                <Flex justify='center' align='center'>
                    <Button
                        onClick={ () => showModal( record ) }
                        size='small'
                        type='primary'>Edit</Button>
                </Flex>
            )
        },
        {
            title: 'Blok',
            dataIndex: 'blokKav',
            align: 'center',
            onFilter: ( value, record ) => record.blokKav.startsWith( value ),
        },
        {
            title: 'Luas Tanah',
            dataIndex: 'luastanah',
            render: ( value ) => { renderTableData( value, 'M²' ) }
        },
        {
            title: 'Luas Bangunan',
            dataIndex: 'luasbangunan',
            render: ( value ) => { renderTableData( value, 'M²' ) }
        },
        {
            title: 'Kamar Tidur',
            dataIndex: 'kamartidur',
            align: 'center',
        },
        {
            title: 'Kamar Mandi',
            dataIndex: 'kamarmandi',
            align: 'center',
        },
        {
            title: 'Ruang Tamu',
            dataIndex: 'ruangtamu',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
        },
        {
            title: 'Dapur',
            dataIndex: 'dapur',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
        },
        {
            title: 'Carport',
            dataIndex: 'carport',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
        },
        {
            title: 'Taman',
            dataIndex: 'taman',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
            align: 'center'
        },
        {
            title: 'Ruang Terbuka',
            dataIndex: 'ruangterbuka',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
        },
        {
            title: 'Ruang Makan',
            dataIndex: 'ruangmakan',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
        },
        {
            title: 'Kanopi',
            dataIndex: 'kanopi',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
        },
        {
            title: 'Pagar',
            dataIndex: 'pagar',
            render: ( value ) =>
                <Flex justify='center' align='center'>
                    { value ? <AiOutlineCheckCircle color="green" /> : <AiOutlineCloseCircle color="red" /> }
                </Flex>,
        }
    ];

    const onChangeTable = ( pagination, filters, sorter, extra ) =>
    {
        console.log( 'params', pagination, filters, sorter, extra );
    };

    return (
        <Flex>
            <section>
                <Sidebar />
            </section>
            <section className='sub-main-layout'>
                <Card className='card-table'>
                    <p className='title-list'>UPDATE LIST RUANGAN</p>
                    <Table
                        columns={ columns }
                        dataSource={ updateHouseList }
                        rowKey='_id'
                        onChange={ onChangeTable }
                        scroll={ { x: 400 } }
                        pagination={ { pageSize: 10 } }
                    />
                </Card>
            </section>

            <Modal
                title="Tambah / Hapus Ruangan"
                visible={ modalVisible }
                onOk={ handleOk }
                confirmLoading={ confirmLoading }
                onCancel={ handleCancel }
            >
                <Form>
                    <Checkbox indeterminate={ indeterminate } onChange={ onCheckAllChange } checked={ checkAll }>
                        Pilih Semua
                    </Checkbox>
                    <hr className='hr' />
                    <CheckboxGroup value={ checkedList } onChange={ onChange }>
                        <Checkbox value="ruangmakan">Ruang Makan</Checkbox>
                        <Checkbox value="kamarmandi">Kamar Mandi</Checkbox>
                        <Checkbox value="kamartidur">Kamar Tidur</Checkbox>
                        <Checkbox value="carport">Carport</Checkbox>
                        <Checkbox value="dapur">Dapur</Checkbox>
                        <Checkbox value="ruangtamu">Ruang Tamu</Checkbox>
                        <Checkbox value="taman">Taman</Checkbox>
                        <Checkbox value="ruangterbuka">Ruang Terbuka</Checkbox>
                        <Checkbox value="kanopi">Kanopi</Checkbox>
                        <Checkbox value="pagar">Pagar</Checkbox>
                    </CheckboxGroup>
                    <hr className='hr' />
                    <div className='div-input'>
                        <label htmlFor='kamarTidur'>Kamar Tidur</label>
                        <InputNumber
                            value={ kamartidur }
                            min={ 0 }
                            placeholder='Jumlah'
                            onChange={ ( value ) => setKamarTidur( value ) }
                        />
                    </div>
                    <div className='div-input'>
                        <label htmlFor='kamarMandi'>Kamar Mandi</label>
                        <InputNumber
                            value={ kamarmandi }
                            min={ 0 }
                            placeholder='Jumlah'
                            onChange={ ( value ) => setKamarMandi( value ) }
                        />
                    </div>
                </Form>
            </Modal>
        </Flex>
    );
};

export default UpdateRuangan;
