import React from 'react';
import Sidebar from './Sidebar';
import { useAuth } from '../Authentication/contexts/authContext';
import { Avatar, Button, Card, Flex, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';

const Dashboard = () =>
{
    const { userData, logout } = useAuth();

    return (
        <Flex>
            <section>
                <Sidebar />
            </section>
            <section className='dashboard-section'>
                <Card className='profile-card' size='small'>
                    <Flex vertical align='center'>
                        <Typography.Text className='greeting-dashboard'>Selamat Datang!</Typography.Text>
                        <Avatar size={ 100 } icon={ <UserOutlined /> } />
                        <Typography.Text className='username'
                        >
                            { userData.name }
                        </Typography.Text>
                        <Typography.Text type='secondary' strong>{ userData.email }</Typography.Text>
                        <Button size='large' className='logout-btn' onClick={ logout }>Logout</Button>
                    </Flex>
                </Card>
            </section>
        </Flex>
    );
}

export default Dashboard;
