import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GoVerified } from "react-icons/go";
import { BsHouses, BsLayers } from "react-icons/bs";
import { GiMoneyStack, GiTakeMyMoney } from "react-icons/gi";
import { MdFence, MdOutlineMoney, MdOutlineGarage, MdOutlineBathroom, MdOutlineKitchen } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import { BiSolidCarGarage } from "react-icons/bi";
import { TbMoneybag } from "react-icons/tb";
import { AiOutlineCalculator } from "react-icons/ai";
import { PiCertificateLight, PiParkLight } from "react-icons/pi";
import { GrMoney, GrStatusGood } from "react-icons/gr";
import { FaHandHoldingWater, FaWhatsapp } from "react-icons/fa";
import { RxDotFilled } from "react-icons/rx";
import { LiaRoadSolid, LiaBedSolid } from "react-icons/lia";
import { GiElectric } from "react-icons/gi";
import './listingDetails.css';
import axios from 'axios';
import { Button, Carousel, Divider, Image, Input, InputNumber, Select, Spin, Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import Header from '../../HeaderFooter/Header';
import Footer from '../../HeaderFooter/Footer';
import './listingDetails.css'
const ListingPage = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const { id } = useParams();
    const [ properties, setProperties ] = useState( [] );
    const [ uangMuka, setUangMuka ] = useState( '' );
    const [ tenor, setTenor ] = useState();
    const [ uangMukaFormatted, setUangMukaFormatted ] = useState( '' );
    const [ angsuranInhouse, setAngsuranInhouse ] = useState( '' );
    const [ angsuranKUR, setAngsuranKUR ] = useState( '' );
    const [ minDPInhouse, setMinDPInhouse ] = useState( '' );
    const [ minDPKUR, setMinDPKUR ] = useState( '' );
    const [ propertyTypeCount, setPropertyTypeCount ] = useState( 0 );
    const [ allProperties, setAllProperties ] = useState( [] );
    const [ denahVisible, setDenahVisible ] = useState( false );
    const [ loading, setLoading ] = useState( true );

    useEffect( () =>
    {
        axios.get( `${ API_URL }` )
            .then( result =>
            {
                const data = result.data;
                const filteredProperties = data.filter( item => item._id === id );
                setProperties( filteredProperties );
                setLoading( false );
                if ( filteredProperties.length > 0 )
                {
                    const type = filteredProperties[ 0 ].jenis;
                    const count = result.data.filter( property => property.jenis === type ).length;
                    setPropertyTypeCount( count );
                }
            } )
            .catch( err =>
            {
                console.log( err );
                setLoading( false );
            } );
    }, [ id ] );

    // Kalkulasi Angsuran Inhouse
    useEffect( () =>
    {
        if ( properties.length > 0 && uangMuka !== '' && tenor )
        {
            if ( parseInt( uangMuka ) < parseInt( properties[ 0 ]?.minDPInhouse ) )
            {
                setAngsuranInhouse( `DP Minimal ${ formatCurrencyIDR( properties[ 0 ]?.minDPInhouse ) }` );
            } else
            {
                const harga = properties[ 0 ]?.harga;
                const bungaKredit = properties[ 0 ]?.bungaKredit;
                const totalKredit = harga + bungaKredit - parseInt( uangMuka );
                const angsuran = Math.floor( totalKredit / tenor );
                if ( angsuran <= 0 )
                {
                    setAngsuranInhouse( 'DP Melebihi Batas' );
                } else
                {
                    setAngsuranInhouse( formatCurrencyIDR( angsuran ) );
                }
            }
        } else
        {
            if ( uangMuka === '' )
            {
                setAngsuranInhouse( 'Masukkan Uang Muka' );
            }
            else if ( !tenor )
            {
                setAngsuranInhouse( 'Pilih Tenor ' );
            }
            else
            {
                setAngsuranInhouse( '' );
            }
        }
    }, [ properties, uangMuka, tenor ] );

    // Kalkulasi Angsuran Subsidi
    useEffect( () =>
    {
        if ( properties.length > 0 && uangMuka !== '' && tenor )
        {
            if ( parseInt( uangMuka ) < parseInt( properties[ 0 ]?.minDPKUR ) )
            {
                setAngsuranKUR( `DP Minimal ${ formatCurrencyIDR( properties[ 0 ]?.minDPKUR ) }` );
            } else
            {
                const harga = properties[ 0 ]?.harga;
                const bungaKUR = properties[ 0 ]?.bungaKUR;
                const totalKredit = harga + bungaKUR - parseInt( uangMuka );
                const angsuran = Math.floor( totalKredit / tenor );
                if ( angsuran <= 0 )
                {
                    setAngsuranKUR( 'DP Melebihi Batas' );
                } else
                {
                    setAngsuranKUR( formatCurrencyIDR( angsuran ) );
                }
            }
        } else
        {
            if ( uangMuka === '' )
            {
                setAngsuranKUR( 'Masukkan Uang Muka' );
            }
            else if ( !tenor )
            {
                setAngsuranKUR( 'Pilih Tenor' );
            }
            else
            {
                setAngsuranKUR( '' );
            }
        }
    }, [ properties, uangMuka, tenor ] );

    const handleChangeUangMuka = ( value ) =>
    {
        setUangMuka( value );
        if ( value !== '' )
        {
            const formattedValue = formatCurrencyIDR( value );
            setUangMukaFormatted( formattedValue );
        }
    };

    const handleChangeTenor = ( value ) =>
    {
        setTenor( value );
    };

    const formatCurrencyIDR = ( amount ) =>
    {
        return new Intl.NumberFormat( 'id-ID', { style: 'currency', currency: 'IDR' } ).format( amount );
    };

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', { style: 'currency', currency: 'IDR' } ).format( value );
    };

    const calculateAngsuran = ( property ) =>
    {
        if ( property.jenis === 'Rumah Kavling' )
        {
            return Math.floor( ( property.harga + property.bungaKredit - property.minDPInhouse ) / 60 );
        } else if ( property.jenis === 'Tanah Kavling' )
        {
            return Math.floor( ( property.harga + property.bungaKredit - property.minDPInhouse ) / 48 );
        } else
        {
            return 'Jenis properti tidak valid';
        }
    };

    return (
        <section>
            <Header />
            <div className='default-section'>
                { loading ? (
                    <div className='spinner-container'>
                        <Spin tip='Loading Property' size='large' />
                    </div>
                ) : (
                    <>
                        { properties.map( property => (
                            <div key={ property.id }>
                                <div>
                                    <Carousel autoplay>
                                        { [ property.image1Url, property.image2Url, property.image3Url, property.image4Url, property.image5Url, property.image6Url ]
                                            .filter( url => url )
                                            .map( ( url, index ) => (
                                                <div key={ index }>
                                                    <div className='bingkai-detail'>
                                                        <img
                                                            className='gambar-detail'
                                                            src={ `${ API_URL }/${ url }` } alt='' />
                                                    </div>
                                                </div>
                                            ) ) }
                                    </Carousel>
                                </div>
                                <section className='section-detail'>
                                    <div className='detail-listingPage'>
                                        <div>
                                            <div className='sub-detail0'>
                                                <div className='sub-item-detail0'>
                                                    { property.label !== '' && (
                                                        <p className='listing-rate'><GoVerified />{ property.label }</p>
                                                    ) }
                                                    <p className='listing-rate1'>SISA { propertyTypeCount } UNIT</p>
                                                    <Link to='https://wa.me/6282336931024' target='_blank' rel='noopener noreferrer'>
                                                        <p className='chat-wa'>CHAT SEKARANG<FaWhatsapp className='wa' /></p>
                                                    </Link>
                                                </div>
                                                <Divider className='ddv' />
                                                <p className='type-property'><RxDotFilled />{ property.jenis }</p>
                                            </div>
                                            <p className='detail-harga'>{ convertToIDR( property.harga ) }</p>
                                            <Divider className='ddv' />
                                        </div>
                                        <div className='detail1'>
                                            <p className='location'><SlLocationPin />Lokasi</p>
                                            <p>{ property.alamat }</p>
                                            <Divider className='ddv' />
                                        </div>
                                        <div>
                                            <p className='title-payment'><GrMoney />Opsi Pembayaran</p>
                                            <div className='main-sub'>
                                                { property.cashKeras !== '' && (
                                                    <p className='item-detail1'><GiMoneyStack />Cash Keras</p>
                                                ) }
                                                { property.cashTempo !== '' && (
                                                    <p className='item-detail1'><MdOutlineMoney />Cash Tempo</p>
                                                ) }
                                                <p className='item-detail1'><TbMoneybag />Kredit s/d { property.tenorMaks } Tahun</p>
                                            </div>
                                            <Divider className='ddv' />
                                            <p className='title-payment'><GiTakeMyMoney />Perincian Pembayaran</p>
                                            <div className='item-payments'>
                                                <p>Uang Muka : { convertToIDR( property.minDPInhouse ) }</p>
                                                <p>Angsuran  : { `${ convertToIDR( calculateAngsuran( property ) ).split( ',' )[ 0 ] } x ${ property.jenis === 'Rumah Kavling' ? 60 : 48 } Bulan` }</p>
                                            </div>
                                            <Divider className='ddv' />
                                        </div>
                                        <div>
                                            <p className='spesification-detail'><GrStatusGood />Spesifikasi & Fasilitas</p>
                                            <div className='main-sub'>
                                                <p className='item-detail1'><PiCertificateLight /> Petok D</p>
                                                <p className='item-detail1'><BsLayers /> Luas Tanah { property.luastanah } M²</p>
                                                <p className='item-detail1'><FaHandHoldingWater /> Air</p>
                                                <p className='item-detail1'><GiElectric /> Listrik</p>
                                                <p className='item-detail1'><LiaRoadSolid /> Jalan 6 Meter</p>
                                                { property.luasbangunan && property.luasbangunan !== '0' && (
                                                    <p className='item-detail1'><BsHouses /> Type { property.luasbangunan }</p>
                                                ) }
                                                { property.kamartidur && property.kamartidur !== '0' && (
                                                    <p className='item-detail1'><LiaBedSolid /> { property.kamartidur } Kamar Tidur</p>
                                                ) }
                                                { property.kamarmandi && property.kamarmandi !== '0' && (
                                                    <p className='item-detail1'><MdOutlineBathroom /> { property.kamarmandi } Kamar Mandi</p>
                                                ) }
                                                { property.carport && property.carport !== '0' && (
                                                    <p className='item-detail1'><MdOutlineGarage /> Carport</p>
                                                ) }
                                                { property.dapur && property.dapur !== '0' && (
                                                    <p className='item-detail1'><MdOutlineKitchen /> Dapur</p>
                                                ) }
                                                { property.kanopi && property.kanopi !== '0' && (
                                                    <p className='item-detail1'><BiSolidCarGarage /> Kanopi</p>
                                                ) }
                                                { property.pagar && property.pagar !== '0' && (
                                                    <p className='item-detail1'><MdFence /> Pagar</p>
                                                ) }
                                            </div>
                                        </div>
                                        { property.denahUrl !== '' && (
                                            <>
                                                <Divider className='ddv' />
                                                <div>
                                                    <Button onClick={ () => setDenahVisible( true ) }>Lihat Denah</Button>
                                                    <Image
                                                        preview={ {
                                                            visible: denahVisible,
                                                            onVisibleChange: ( value ) => setDenahVisible( value ),
                                                            src: `${ API_URL }/${ property.denahUrl }`
                                                        } }
                                                    />
                                                </div>
                                            </>
                                        ) }
                                    </div>
                                    <div className='sub-detail-listing-page'>
                                        <p className='title-calculator'><AiOutlineCalculator />Kalkulator Simulasi Kredit</p>
                                        <Divider className='ddv' />
                                        <Tabs alig defaultActiveKey='1'>
                                            <TabPane tab='Inhouse' key='1'>
                                                <div className='content-calculator'>
                                                    <p className='subtitle-calculator'>Masukkan Besar Uang Muka</p>
                                                    <InputNumber
                                                        type='number'
                                                        placeholder='Masukkan Uang Muka'
                                                        value={ uangMuka }
                                                        className='input-uangmuka'
                                                        onChange={ handleChangeUangMuka }
                                                        min={ 0 }
                                                    />
                                                    <Divider className='ddv' />
                                                    <p className='subtitle-calculator'>Pilih Jangka Waktu Kredit</p>
                                                    <Select
                                                        className='calc-input'
                                                        onChange={ handleChangeTenor }
                                                        placeholder='Tentukan Tenor'
                                                        value={ tenor }
                                                    >
                                                        <Select.Option value={ 12 }>12 Bulan</Select.Option>
                                                        <Select.Option value={ 24 }>24 Bulan</Select.Option>
                                                        <Select.Option value={ 36 }>36 Bulan</Select.Option>
                                                        <Select.Option value={ 48 }>48 Bulan</Select.Option>
                                                        { property.jenis === 'Rumah Kavling' && (
                                                            <Select.Option value={ 60 }>60 Bulan</Select.Option>
                                                        ) }
                                                    </Select>
                                                    <Divider className='ddv' />
                                                    <p className='subtitle-calculator'>Angsuran Perbulan</p>
                                                    <Input
                                                        type='text'
                                                        className='hasil-angsuran'
                                                        placeholder='Angsuran perbulan'
                                                        value={ angsuranInhouse }
                                                        readOnly
                                                    />
                                                </div>
                                            </TabPane>
                                            <TabPane disabled={ !property.kur } tab='Subsidi' key='2'>
                                                <div className='content-calculator'>
                                                    <p className='subtitle-calculator'>Masukkan Besar Uang Muka</p>
                                                    <InputNumber
                                                        type='number'
                                                        placeholder='Masukkan Uang Muka'
                                                        value={ uangMuka }
                                                        className='input-uangmuka'
                                                        onChange={ handleChangeUangMuka }
                                                        min={ 0 }
                                                    />
                                                    <Divider className='ddv' />
                                                    <p className='subtitle-calculator'>Pilih Jangka Waktu Kredit</p>
                                                    <Select
                                                        className='calc-input'
                                                        onChange={ handleChangeTenor }
                                                        placeholder='Tentukan Tenor'
                                                        value={ tenor }
                                                    >
                                                        <Select.Option value={ 12 }>12 Bulan</Select.Option>
                                                        <Select.Option value={ 24 }>24 Bulan</Select.Option>
                                                        <Select.Option value={ 36 }>36 Bulan</Select.Option>
                                                        <Select.Option value={ 48 }>48 Bulan</Select.Option>
                                                        { property.jenis === 'Rumah Kavling' && (
                                                            <Select.Option value={ 60 }>60 Bulan</Select.Option>
                                                        ) }
                                                    </Select>
                                                    <Divider className='ddv' />
                                                    <p className='subtitle-calculator'>Angsuran Perbulan</p>
                                                    <Input
                                                        className='hasil-angsuran'
                                                        placeholder='Angsuran perbulan'
                                                        value={ angsuranKUR }
                                                        readOnly
                                                    />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </section>
                            </div>
                        ) ) }
                    </>
                ) }
            </div>
            <Footer />
        </section>
    );
};

export default ListingPage;
