import React, { useState } from 'react';
import axios from 'axios';
import '../PropertyData.css';
import { Button, Card, Flex, Form, Input, InputNumber, Select, Space, notification } from 'antd'; // Import notification dari antd
import Sidebar from '../Sidebar';
import { PiBank } from "react-icons/pi";
import { GiCash, GiTakeMyMoney, GiMoneyStack } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';

const TambahProperti = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ judul, setJudul ] = useState( '' );
    const [ stats, setStats ] = useState( '' );
    const [ harga, setHarga ] = useState( '' );
    const [ jenis, setJenis ] = useState( '' );
    const [ label, setLabel ] = useState( '' );
    const [ deskripsi, setDeskripsi ] = useState( '' );
    const [ lokasi, setLokasi ] = useState( '' );
    const [ blokKav, setBlokKav ] = useState( '' );
    const [ hadiah, setHadiah ] = useState( '' );
    const [ alamat, setAlamat ] = useState( '' );
    const [ luastanah, setLuasTanah ] = useState( '' );
    const [ jalankavling, setJalanKavling ] = useState( '' );
    const [ cashKeras, setCashKeras ] = useState( '' );
    const [ cashTempo, setCashTempo ] = useState( '' );
    const [ kredit, setKredit ] = useState( '' );
    const [ tenorMaks, setTenorMaks ] = useState( '' );
    const [ bungaKredit, setBungaKredit ] = useState( '' );
    const [ bungaKUR, setBungaKUR ] = useState( '' );
    const [ kur, setKur ] = useState( '' );
    const [ minDPKUR, setMinDPKUR ] = useState( '' )
    const [ minDPInhouse, setMinDPInhouse ] = useState( '' )
    const navigate = useNavigate()
    // Submit Handle //
    const handleSubmit = ( e ) =>
    {
        axios.post( `${ API_URL }/addProperty`, {
            judul,
            stats,
            harga,
            jenis,
            label,
            deskripsi,
            minDPInhouse,
            minDPKUR,
            lokasi,
            blokKav,
            hadiah,
            alamat,
            luastanah,
            jalankavling,
            cashKeras,
            cashTempo,
            kredit,
            tenorMaks,
            bungaKredit,
            bungaKUR,
            kur,
        } )
            .then( result =>
            {
                console.log( result );
                openNotification( 'topRight' );
                resetForm();
            } )
            .catch( err =>
            {
                console.log( err );
                openNotification( 'topRight' );
            } );
    };
    // Reset Handle //
    const resetForm = () =>
    {
        setBlokKav( '' );
    };
    // Payments Handle //
    const handleChangePayments = ( value ) =>
    {
        if ( value.includes( 'cash-keras' ) )
        {
            setCashKeras( 'cash-keras' );
        } else
        {
            setCashKeras( '' );
        }

        if ( value.includes( 'cash-tempo' ) )
        {
            setCashTempo( 'cash-tempo' );
        } else
        {
            setCashTempo( '' );
        }

        if ( value.includes( 'kredit' ) )
        {
            setKredit( 'kredit' );
        } else
        {
            setKredit( '' );
        }


        if ( value.includes( 'KUR' ) )
        {
            setKur( 'KUR' );
        } else
        {
            setKur( '' );
        }
    };
    const options = [
        {
            label: <GiCash />,
            value: 'cash-keras',
            emoji: <GiCash />,
            desc: 'Cash Keras',
        },
        {
            label: <GiTakeMyMoney />,
            value: 'cash-tempo',
            emoji: <GiTakeMyMoney />,
            desc: 'Cash Tempo',
        },
        {
            label: <GiMoneyStack />,
            value: 'kredit',
            emoji: <GiMoneyStack />,
            desc: 'Kredit',
        },
        {
            label: <PiBank />,
            value: 'KUR',
            emoji: <PiBank />,
            desc: 'KUR',
        },
    ]
    // Notification //
    const openNotification = ( placement ) =>
    {
        notification.info( {
            message: `Pemberitahuan ${ placement }`,
            description:
                'Properti Berhasil Di Tambahkan',
            placement,
        } );
    };
    // Navigate Back //
    const handleNavigateBack = () =>
    {
        navigate( -1 );
    };
    // Alamat Handle //
    const handleChangeAlamat = ( value ) =>
    {
        setAlamat( value );
    };
    // Lokasi Handle /
    const handleChangeLokasi = ( value ) =>
    {
        setLokasi( value );
    };
    // Status Handle //
    const handleChangeStatus = ( value ) =>
    {
        setStats( value );
    };
    // Jenis Handle //
    const handleChangeJenis = ( value ) =>
    {
        setJenis( value );
    };
    // Tenor Handle //
    const handleChangeTenor = ( value ) =>
    {
        setTenorMaks( value );
    };
    return (
        <Flex>
            <section>
                <Sidebar />
            </section>
            <section className='main-edit-section'>
                <Card title='Tambah Properti Baru'>
                    <Form onFinish={ handleSubmit }>
                        <section>
                            <Flex gap={ 20 } justify='center' align='center'>
                                <div>
                                    {/*Blok Kavling*/ }
                                    <div className='div-input'>
                                        <label htmlFor='blokKav'>Blok Kavling</label>
                                        <Input
                                            placeholder='Masukan Blok Kavling'
                                            value={ blokKav }
                                            className='input-form'
                                            onChange={ ( e ) => setBlokKav( e.target.value ) }
                                            required
                                        />
                                    </div>
                                    {/*Judul*/ }
                                    <div className='div-input'>
                                        <label htmlFor='judul'>Judul</label>
                                        <Input
                                            placeholder='Masukan Judul Property'
                                            value={ judul }
                                            className='input-form'
                                            onChange={ ( e ) => setJudul( e.target.value ) }
                                        />
                                    </div>
                                    {/*Status*/ }
                                    <div className='div-input'>
                                        <label htmlFor='stats'>Status</label>
                                        <Select
                                            placeholder='Tentukan Status Property'
                                            onChange={ handleChangeStatus }
                                            loading
                                            options={ [
                                                { value: 'Ready', label: 'Ready' },
                                                { value: 'Inden', label: 'Inden' },
                                                { value: 'Sold', label: 'Sold' },
                                            ] }
                                        />
                                    </div>
                                    {/*Lokasi*/ }
                                    <div className='div-input'>
                                        <label htmlFor='lokasi'>Lokasi</label>
                                        <Select
                                            placeholder='Tentukan Lokasi Property'
                                            onChange={ handleChangeLokasi }
                                            loading
                                            options={ [
                                                { value: 'Domas, Menganti', label: 'Domas' },
                                                { value: 'Petal, Menganti', label: 'Petal' },
                                                { value: 'Tanjung, Kedamean', label: 'Kedamean' },
                                            ] }
                                        />
                                    </div>
                                    {/*Alamat*/ }
                                    <div className='div-input'>
                                        <label htmlFor='alamat'>Alamat Properti</label>
                                        <Select
                                            placeholder='Tentukan Alamat Properti'
                                            onChange={ handleChangeAlamat }
                                            loading
                                            options={ [
                                                { value: 'Desa Domas, Kec. Menganti, Kab. Gresik', label: 'Domas' },
                                                { value: 'Dusun Petal, Kec. Menganti, Kab. Gresik', label: 'Petal' },
                                                { value: 'Desa Tanjung, Kec. Kedamean, Kab. Gresik', label: 'Kedamean' },
                                            ] }
                                        />
                                    </div>
                                </div>
                                <div>
                                    {/*Jenis*/ }
                                    <div className='div-input'>
                                        <label htmlFor='jenis'>Jenis Properti</label>
                                        <Select
                                            placeholder='Pilih Jenis Property'
                                            onChange={ handleChangeJenis }
                                            loading
                                            options={ [
                                                { value: 'Rumah Kavling', label: 'Rumah' },
                                                { value: 'Tanah Kavling', label: 'Tanah' },
                                            ] }
                                        />
                                    </div>
                                    {/*Luas Tanah*/ }
                                    <div className='div-input'>
                                        <label htmlFor='luastanah'>Luas Tanah</label>
                                        <Input
                                            placeholder='Masukkan Luas Tanah'
                                            value={ luastanah }
                                            className='input-form'
                                            onChange={ ( e ) => setLuasTanah( e.target.value ) }
                                            required
                                        />
                                    </div>
                                    {/*Jalan Kavling*/ }
                                    <div className='div-input'>
                                        <label htmlFor='jalankavling'>Jalan Kavling</label>
                                        <Input
                                            placeholder='Masukkan Lebar Jalan'
                                            className='input-form'
                                            value={ jalankavling }
                                            onChange={ ( e ) => setJalanKavling( e.target.value ) }
                                            required
                                        />
                                    </div>
                                    {/*Harga*/ }
                                    <div className='div-input'>
                                        <label htmlFor='harga'>Harga</label>
                                        <InputNumber
                                            placeholder='Masukkan Harga Property'
                                            className='input-form'
                                            value={ harga }
                                            onChange={ setHarga }
                                            required
                                        />
                                    </div>
                                    {/*Metode Pembayaran*/ }
                                    <div className='div-input'>
                                        <label htmlFor='pilihmetodepembayaran'>Pilih Metode Pembayaran</label>
                                        <Select
                                            mode="multiple"
                                            style={ { width: '100%' } }
                                            placeholder="Tentukan Metode Pembayaran"
                                            onChange={ handleChangePayments }
                                            options={ options }
                                            optionRender={ ( option ) => (
                                                <Space>
                                                    <span role="img" aria-label={ option.data.label }>
                                                        { option.data.emoji }
                                                    </span>
                                                    { option.data.desc }
                                                </Space>
                                            ) }
                                        />
                                    </div>
                                </div>
                                <div>
                                    {/*Minimal Dp Inhouse*/ }
                                    <div className='div-input'>
                                        <label htmlFor='MinDPInhouse'>Min DP Inhouse</label>
                                        <InputNumber
                                            placeholder='Min DP Property'
                                            required
                                            value={ minDPInhouse }
                                            className='input-form'
                                            onChange={ setMinDPInhouse }
                                        />
                                    </div>
                                    {/*Minimal Dp KUR*/ }
                                    <div className='div-input'>
                                        <label htmlFor='MinDPKUR'>Min DP KUR</label>
                                        <InputNumber
                                            placeholder='Min DP KUR'
                                            value={ minDPKUR }
                                            className='input-form'
                                            onChange={ setMinDPKUR }
                                        />
                                    </div>
                                    {/*Tenor Maksimal Kredit*/ }
                                    <div className='div-input'>
                                        <label htmlFor='tenorMaks'>Tenor Maksimal</label>
                                        <Select
                                            placeholder='Tentukan Tenor Maks'
                                            onChange={ handleChangeTenor }
                                            loading
                                            options={ [
                                                { value: 1, label: '1 Tahun' },
                                                { value: 2, label: '2 Tahun' },
                                                { value: 3, label: '3 Tahun' },
                                                { value: 4, label: '4 Tahun' },
                                                { value: 5, label: '5 Tahun' },
                                            ] }
                                        />
                                    </div>
                                    {/*Bunga Kredit Inhouse*/ }
                                    <div className='div-input'>
                                        <label htmlFor='bungaKredit'>Bunga Kredit</label>
                                        <InputNumber
                                            value={ bungaKredit }
                                            placeholder='Tambahkan Bunga Kredit'
                                            className='input-form'
                                            onChange={ setBungaKredit }
                                        />
                                    </div>
                                    {/*Bunga KUR*/ }
                                    <div className='div-input'>
                                        <label htmlFor='bungaKUR'>Bunga KUR</label>
                                        <InputNumber
                                            value={ bungaKUR }
                                            placeholder='Tambahkan Bunga KUR'
                                            className='input-form'
                                            onChange={ setBungaKUR }
                                        />
                                    </div>
                                </div>
                                <div>
                                    {/*Label*/ }
                                    <div className='div-input'>
                                        <label htmlFor='label'>Label</label>
                                        <Input
                                            value={ label }
                                            placeholder='Tambahkan Label'
                                            className='input-form'
                                            onChange={ ( e ) => setLabel( e.target.value ) }
                                        />
                                    </div>
                                    {/*Hadiah*/ }
                                    <div className='div-input'>
                                        <label htmlFor='hadiah'>Hadiah</label>
                                        <Input
                                            value={ hadiah }
                                            placeholder='Tambahkan Hadiah'
                                            className='input-form'
                                            onChange={ ( e ) => setHadiah( e.target.value ) }
                                        />
                                    </div>
                                    {/*Deskripsi*/ }
                                    <div className='div-input'>
                                        <label htmlFor='deskripsi'>Deskripsi</label>
                                        <TextArea
                                            id='deskripsi'
                                            className='input-form'
                                            rows='3'
                                            placeholder='Masukkan deskripsi property'
                                            value={ deskripsi }
                                            onChange={ ( e ) => setDeskripsi( e.target.value ) }
                                        />
                                    </div>
                                </div>
                            </Flex>
                        </section>
                        <Flex justify='center' gap='middle'>
                            {/*Tombol Kembali*/ }
                            <Button
                                onClick={ handleNavigateBack }>
                                Kembali
                            </Button>
                            {/*Tombol Tambah*/ }
                            <Button
                                htmlType='submit'
                                type='primary'>
                                Tambahkan
                            </Button>
                        </Flex>
                    </Form>
                </Card>
            </section>
        </Flex >
    );
};

export default TambahProperti;
