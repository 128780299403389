import React from 'react'
import useLogin from './hooks/useLogin'
import { Alert, Button, Card, Flex, Form, Input, Spin, Typography } from 'antd'
import './styleauth.css'

const Login = () =>
{
    const { error, loading, loginUser } = useLogin();
    const handleLogin = async ( values ) =>
    {
        await loginUser( values )
    }
    return (
        <div className='auth-form'>
            <Card className='auth-container'>
                <Flex gap='large' align='center'>
                    {/*form*/ }
                    <Flex vertical flex={ 1 }>
                        <Typography.Text className='auth-title'>
                            Masuk
                        </Typography.Text>
                        <Typography.Text className='greeting-auth'>Mari Kita Raih Masa Depan!</Typography.Text>
                        <Form layout='vertical' onFinish={ handleLogin } autoComplete='off'>
                            <Form.Item
                                label={ <span className="auth-label">Email</span> }
                                name='email'
                                rules={ [
                                    {
                                        required: true,
                                        message: 'Masukkan Email Akun Anda!',
                                    },
                                    {
                                        type: 'email',
                                        message: 'Email Tidak Valid!',
                                    },
                                ] }>
                                <Input size='large' placeholder='Masukkan Email Anda' />
                            </Form.Item>

                            <Form.Item
                                label={ <span className="auth-label">Password</span> }
                                name='password'
                                rules={ [
                                    {
                                        required: true,
                                        message: 'Masukkan Password Akun Anda!',
                                    }
                                ] }>
                                <Input.Password size='large' placeholder='Masukkan Password Anda' />
                            </Form.Item>

                            {
                                error && <Alert description={ error } showIcon closable className='auth-alert' />
                            }

                            <Form.Item>
                                <Flex
                                    gap={ 5 }
                                    justify='center'
                                    align='center'>
                                    <Button
                                        type={ `${ loading ? '' : 'primary' }` }
                                        size='large'
                                        htmlType='submit'
                                        className='auth-button'
                                    >{ loading ? <Spin /> : 'Sign In' }</Button>
                                </Flex>
                            </Form.Item>

                        </Form>
                    </Flex>
                    {/*image*/ }
                    <Flex></Flex>
                </Flex>
            </Card>
        </div>
    )
}

export default Login
