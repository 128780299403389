import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { TfiGallery } from "react-icons/tfi";
import { IoIosAddCircleOutline } from "react-icons/io"
import { FaGripfire } from "react-icons/fa";
import { BsHouseGear, BsLayers } from "react-icons/bs";
import { CiCalculator2 } from "react-icons/ci";
import { TbMapPinPin } from "react-icons/tb";
const { Sider } = Layout;

const Sidebar = () =>
{
    const [ collapsed, setCollapsed ] = useState( true );

    const toggleCollapse = () =>
    {
        setCollapsed( !collapsed );
    };

    return (
        <Layout className='layout'>
            <Sider className={ `sidebar ${ collapsed ? 'collapsed' : '' }` } collapsible collapsed={ collapsed } trigger={ null } theme="light">
                <div className='dashboard-button' onClick={ toggleCollapse }>
                    <FaGripfire />
                </div>
                <Menu mode='inline' className="menu-bar" >
                    <Menu.Item className='menu-item' key="home" icon={ <HomeOutlined /> }>
                        <Link to="/dashboard">
                            Home
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="add-listing" icon={ <IoIosAddCircleOutline /> }>
                        <Link to='/add-property/'>Add Property</Link>
                    </Menu.Item>
                    <Menu.SubMenu key='kavling' title='Kavling' icon={ <BsLayers /> }>
                        <Menu.Item key='kavling-domas' icon={ <TbMapPinPin /> }>
                            <Link to='/update-kavling-domas/'>Domas</Link>
                        </Menu.Item>
                        <Menu.Item key='kavling-petal' icon={ <TbMapPinPin /> }>
                            <Link to='/update-kavling-petal/'>Petal</Link>
                        </Menu.Item>
                        <Menu.Item key='kavling-kedamean' icon={ <TbMapPinPin /> }>
                            <Link to='/update-kavling-tanjung/'>Tanjung</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key='building' title='Building' icon={ <BsHouseGear /> }>
                        <Menu.Item key='building-domas' icon={ <TbMapPinPin /> }>
                            <Link to='/update-building-domas/'>Domas</Link>
                        </Menu.Item>
                        <Menu.Item key='building-petal' icon={ <TbMapPinPin /> }>
                            <Link to='/update-building-petal/'>Petal</Link>
                        </Menu.Item>
                        <Menu.Item key='building-kedamean' icon={ <TbMapPinPin /> }>
                            <Link to='/update-building-tanjung/'>Tanjung</Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu title='Gallery' key='gallery' icon={ <TfiGallery /> }>
                        <Menu.Item key='album-Domas' icon={ <TbMapPinPin /> }>
                            <Link to='/gallery-domas/'>
                                Domas
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='album-petal' icon={ <TbMapPinPin /> }>
                            <Link to='/gallery-petal/'>
                                Petal
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='album-tanjung' icon={ <TbMapPinPin /> }>
                            <Link to='/gallery-tanjung/'>
                                Tanjung
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.Item key='statistic' icon={ <CiCalculator2 /> }>
                        <Link to='/statistic-data'>
                            Statistic Data
                        </Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        </Layout>
    );
}

export default Sidebar;
