import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ( { children } ) =>
{
    const [ token, setToken ] = useState( null );
    const [ userData, setUserData ] = useState( null );
    const [ isAuthenticated, setAuthenticated ] = useState( false );

    useEffect( () =>
    {
        const storedData = JSON.parse( localStorage.getItem( 'user_data' ) );
        if ( storedData )
        {
            const { userToken, user } = storedData;
            setToken( userToken );
            setUserData( user );
            setAuthenticated( true );
        }
    }, [] );

    const login = ( newToken, newData ) =>
    {
        localStorage.setItem(
            'user_data',
            JSON.stringify( { userToken: newToken, user: { ...newData, name: newData.name } } )
        );
        setToken( newToken );
        setUserData( newData );
        setAuthenticated( true );
    };

    const logout = () =>
    {
        localStorage.removeItem( 'user_data' );
        setToken( null );
        setUserData( null );
        setAuthenticated( false );
    };

    return (
        <AuthContext.Provider value={ { token, isAuthenticated, login, logout, userData } }>
            { children }
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext( AuthContext );
