import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../PropertyData.css'
import Sidebar from '../../Sidebar';
import { Button, Card, Flex, Table } from 'antd';
import { CiEdit } from 'react-icons/ci';
import { MdOutlineDeleteOutline } from 'react-icons/md';

const UpdateKavlingPetal = () =>
{
    const API_URL = 'https://api-z.royaltanjung.com';
    const [ pages ] = useState();
    const [ updateHouseList, setUpdateHouseList ] = useState( [] );
    useEffect( () =>
    {
        axios.get( `${ API_URL }`, {
        } )
            .then( result =>
            {
                const data = result.data;
                const filteredLokasiKavling = data.filter( item => item.lokasi === 'Petal, Menganti' )
                setUpdateHouseList( filteredLokasiKavling )
            } )
            .catch( err => console.log( err ) );
    }, [] );

    const convertToIDR = ( value ) =>
    {
        return new Intl.NumberFormat( 'id-ID', { style: 'currency', currency: 'IDR' } ).format( value );
    }

    const handleDelete = ( id ) =>
    {
        axios.delete( `${ API_URL }/deleteProperty/` + id )
            .then( res =>
            {
                console.log( res );
                window.location.reload();
            } )
            .catch( err => console.log( err ) );
    }

    const columns = [
        {
            title: 'Tindakan',
            dataIndex: 'actions',
            render: ( text, record ) => (
                <Flex gap={ 5 } justify='center' align='center'>
                    <Link to={ `/update-property/${ record._id }` }>
                        <Button size='small' type='primary'>
                            <CiEdit />
                        </Button>
                    </Link>
                    <Button onClick={ () => handleDelete( record._id ) } size='small' danger>
                        <MdOutlineDeleteOutline />
                    </Button>
                </Flex>
            )
        },
        {
            title: 'Blok',
            dataIndex: 'blokKav',
            sorter: ( a, b ) => a.blokKav.localeCompare( b.blokKav )
        },
        {
            title: 'Jenis',
            dataIndex: 'jenis',
            sorter: ( a, b ) => a.jenis.localeCompare( b.jenis )
        },
        {
            title: 'Status',
            dataIndex: 'stats',
            sorter: ( a, b ) => a.stats.localeCompare( b.stats )
        },
        {
            title: 'Lokasi',
            dataIndex: 'lokasi',
            sorter: ( a, b ) => a.lokasi.localeCompare( b.lokasi )
        },
        {
            title: 'LT(M²)',
            dataIndex: 'luastanah',
            render: ( value ) => renderTableData( value, 'M²' )
        },
        {
            title: 'Harga',
            dataIndex: 'harga',
            render: ( value ) => convertToIDR( value ),
            sorter: ( a, b ) => ( a.harga || 0 ) - ( b.harga || 0 ),
        },
        {
            title: 'MinDPInhs',
            dataIndex: 'minDPInhouse',
            render: ( value, record ) =>
            {
                if ( record.minDPInhouse !== null && record.minDPInhouse !== '' )
                {
                    return convertToIDR( value );
                }
                return null;
            },
            sorter: ( a, b ) =>
            {
                const valA = a.minDPInhouse !== null ? a.minDPInhouse : '';
                const valB = b.minDPInhouse !== null ? b.minDPInhouse : '';
                return valA.toString().localeCompare( valB.toString() );
            }
        },
        {
            title: 'MinDPKUR',
            dataIndex: 'minDPKUR',
            render: ( value, record ) =>
            {
                if ( record.minDPKUR !== null && record.minDPKUR !== '' )
                {
                    return convertToIDR( value );
                }
                return null;
            },
            sorter: ( a, b ) =>
            {
                const valA = a.minDPKUR !== null ? a.minDPKUR : '';
                const valB = b.minDPKUR !== null ? b.minDPKUR : '';
                return valA.toString().localeCompare( valB.toString() );
            }
        },
    ];

    const renderTableData = ( value, unit ) =>
    {
        return value ? `${ value } ${ unit }` : '';
    };

    const onChange = ( pagination, filters, sorter, extra ) =>
    {
        console.log( 'params', pagination, filters, sorter, extra );
    };

    return (
        <div className='main-layout'>
            <div>
                <Sidebar />
            </div>
            <div className='sub-main-layout'>
                <Card className='card-table'>
                    <p className='title-list'>UPDATE LIST KAVLING PETAL</p>
                    <Table
                        columns={ columns }
                        dataSource={ updateHouseList }
                        rowKey='_id'
                        sticky
                        tableLayout='auto'
                        onChange={ onChange }
                        pagination={ { pageSize: pages } }
                        scroll={ { y: 340 } }
                        size='small'
                    />
                </Card>
            </div>
        </div>
    );
}

export default UpdateKavlingPetal;
