import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './Home/Home';
import ListingPage from './Listing/ListingDetails/listingDetails';
import Dashboard from './PropertyDashboard/Dashboard';
import TambahProperti from './PropertyDashboard/Edit/TambahProperti';
import UpdateRuangan from './PropertyDashboard/Update/UpdateRuangan';
import UpdateProperti from './PropertyDashboard/Edit/UpdateProperti';
import Login from './Authentication/Login';
import { useAuth } from './Authentication/contexts/authContext';
import PropertiTerjual from './PropertyDashboard/Data/PropertiTerjual';
import UpdateKavlingDomas from './PropertyDashboard/Update/Kavling/KavlingDomas';
import UpdateKavlingPetal from './PropertyDashboard/Update/Kavling/KavlingPetal';
import UpdateKavlingTanjung from './PropertyDashboard/Update/Kavling/KavlingTanjung';
import UpdateBuildingDomas from './PropertyDashboard/Update/Building/BuildingDomas';
import UpdateBuildingPetal from './PropertyDashboard/Update/Building/BuildingPetal';
import UpdateBuildingTanjung from './PropertyDashboard/Update/Building/BuildingTanjung';
import GaleriDomas from './PropertyDashboard/Galeri/GaleriDomas/GaleriDomas';
import GaleriPetal from './PropertyDashboard/Galeri/GaleriPetal/GaleriPetal';
import GaleriTanjung from './PropertyDashboard/Galeri/GaleriTanjung/GaleriTanjung';
import ListingKavling from './Listing/ListingJenis/ListingKavling';
import ListingRumah from './Listing/ListingJenis/ListingRumah';

const App = () =>
{
  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <Routes>
        <Route path='/' element={ <Home /> } />
        <Route path='/rumah-kavling/:id' element={ <ListingPage /> } />
        <Route path='/tanah-kavling/:id' element={ <ListingPage /> } />
        <Route path='/property-details/:id' element={ <ListingPage /> } />

        <Route path='/listing-kavling' element={ <ListingKavling /> } />
        <Route path='/listing-rumah' element={ <ListingRumah /> } />

        <Route path='/login' element={ !isAuthenticated ? <Login /> : <Navigate to='/dashboard' /> } />
        <Route path='/dashboard' element={ isAuthenticated ? <Dashboard /> : <Login /> } />
        <Route path='/update-property/:id' element={ isAuthenticated ? <UpdateProperti /> : <Login /> } />
        <Route path='/add-property' element={ isAuthenticated ? <TambahProperti /> : <Login /> } />
        <Route path='/update-building' element={ isAuthenticated ? <UpdateRuangan /> : <Login /> } />
        <Route path='/statistic-data' element={ isAuthenticated ? <PropertiTerjual /> : <Login /> } />

        <Route path='/update-kavling-domas' element={ isAuthenticated ? <UpdateKavlingDomas /> : <Login /> } />
        <Route path='/update-kavling-petal' element={ isAuthenticated ? <UpdateKavlingPetal /> : <Login /> } />
        <Route path='/update-kavling-tanjung' element={ isAuthenticated ? <UpdateKavlingTanjung /> : <Login /> } />

        <Route path='/update-building-domas' element={ isAuthenticated ? <UpdateBuildingDomas /> : <Login /> } />
        <Route path='/update-building-petal' element={ isAuthenticated ? <UpdateBuildingPetal /> : <Login /> } />
        <Route path='/update-building-tanjung' element={ isAuthenticated ? <UpdateBuildingTanjung /> : <Login /> } />

        <Route path='/gallery-domas' element={ isAuthenticated ? <GaleriDomas /> : <Login /> } />
        <Route path='/gallery-petal' element={ isAuthenticated ? <GaleriPetal /> : <Login /> } />
        <Route path='/gallery-tanjung' element={ isAuthenticated ? <GaleriTanjung /> : <Login /> } />

      </Routes>
    </Router>
  );
};

export default App;
